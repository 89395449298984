$roboto-font-path: '../fonts' !default;

@mixin roboto-font($folder, $variant, $type, $weight, $style) {
  $font-full-path: '#{$roboto-font-path}/rb-#{$variant}';
  $variant-normal: '#{$variant}';
  $variant-type: '#{$variant}-#{$type}';

  @font-face {
    font-family: $variant-normal;
    src:
      url('#{$font-full-path}-#{$type}.woff2') format('woff2'),
      url('#{$font-full-path}-#{$type}.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
  }

  @font-face {
    font-family: $variant-type;
    src:
      url('#{$font-full-path}-#{$type}.woff2') format('woff2'),
      url('#{$font-full-path}-#{$type}.woff') format('woff');
  }
}
