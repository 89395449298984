/* Variables */
@import './theme/variables.scss';
@import './theme/colors.scss';
@import './theme/fonts.scss';
@import './theme/icons.scss';

/* Material remberg base theme */
@import './theme/remberg-material.scss';

/* Theme Subfile overwriting MDC Material */
@import './theme/mdc-text.scss';
@import './theme/mdc-button.scss';
@import './theme/mdc-checkbox.scss';
@import './theme/mdc-input.scss';
@import './theme/mdc-card.scss';
@import './theme/mdc-tab.scss';
@import './theme/mdc-toggle.scss';
@import './theme/mdc-datepicker.scss';
@import './theme/mdc-dialog.scss';
@import './theme/mde-popover.scss';
@import './theme/mdc-overlay.scss';
@import './theme/mdc-menu.scss';
@import './theme/mdc-badge.scss';
@import './theme/mdc-chips.scss';
@import './theme/mdc-stepper.scss';
@import './theme/mdc-list.scss';
@import './theme/mdc-select.scss';
@import './theme/mdc-expansion.scss';
@import './theme/mdc-snackbar.scss';
@import './theme/mdc-tooltip.scss';
@import './theme/mdc-tree.scss';
@import './theme/loader.scss';
@import './theme/toastr.scss';

/* Theme Subfiles */
@import './theme/banner.scss';
@import './theme/cards.scss';
@import './theme/tables.scss';
@import './theme/indicators.scss';
@import './theme/footer.scss';
@import './theme/table-columns.scss';
@import './theme/searchbar.scss';
@import './theme/file-list.scss';
@import './theme/toolbar.scss';
@import './theme/overview-page.scss';
@import './theme/detail-page.scss';
@import './theme/color-picker.scss';
@import './theme/date-time.scss';
@import './theme/metadata.scss';

/* Page Setup */
.host-relative-ios {
  position: relative;
  display: block;
  height: calc(var(--height) - 180px);
}

.host-relative-android {
  position: relative;
  display: block;
  height: calc(var(--height) - 150px);
}

.responsive-host {
  width: 100% !important;
  margin: initial !important;
}

.multiline-tooltip {
  white-space: pre-line;
}

input:focus-visible {
  outline: none;
}
