.banner {
  position: fixed;
  z-index: 100;
  height: 30px;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  text-align: center;
  background: var(--statusRed);
  color: var(--white);
  font-weight: var(--font-weight-bold);
}

.banner > mat-icon.close-banner-icon {
  font-size: var(--smallIcon);
  height: var(--smallIcon);
  width: var(--smallIcon);
  min-width: var(--smallIcon);
  position: absolute;
  right: 15px;
  top: 5px;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: var(--white);
}

.banner > mat-icon.close-banner-icon:hover {
  cursor: pointer;
}

.environment-banner {
  position: fixed;
  z-index: 100;
  left: 50%;
  margin-left: -150px;
  width: 300px;
  align-items: center;
  justify-content: center;
  display: flex;
  text-align: center;
  background: var(--statusOrange);
  color: var(--black);
  font-weight: var(--font-weight-bold);
  border-radius: 0 0 15px 15px;
  line-height: var(--line-height-32px);
}

.center-banner {
  left: 50%;
  margin-left: -50px;
}

.offline-banner {
  width: 100px;
  position: fixed;
  z-index: 100;
  /* width: 300px; */
  align-items: center;
  justify-content: center;
  display: flex;
  text-align: center;
  background: var(--primaryThemeColor);
  color: var(--textColorOnPrimaryBackground);
  font-weight: var(--font-weight-bold);
  border-radius: var(--borderRadius);
  line-height: var(--line-height-24px);
  font-size: var(--font-size-14px);
  top: 19px;
  border: 1px solid transparent;
  left: calc(100% - 200px);
}

.offline-banner-responsive-small {
  left: calc(100% - 100px);
}

.offline-banner-responsive-xSmall {
  left: calc(100% - 120px);
}

.test-and-offline-banner {
  width: 150px;
  left: calc(100% - 220px);
}

.test-and-offline-banner-responsive-small {
  left: calc(100% - 140px);
}

.test-and-offline-banner-responsive-xSmall {
  left: calc(100% - 140px);
}

.offline-banner-hover:hover {
  cursor: pointer;
}

.info-icon-banner {
  font-size: var(--mediumIcon);
  height: var(--mediumIcon);
  width: var(--mediumIcon);
  min-width: var(--mediumIcon);
  position: absolute;
  right: 5px;
  top: 2px;
  color: var(--textColorOnPrimaryBackground) !important;
}

.info-icon-banner:hover {
  cursor: pointer;
}

.show-banner {
  margin-top: 30px !important;
}

.show-banner:after {
  top: 37px !important;
}
