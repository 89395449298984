/* Overlay */
.cdk-overlay-container {
  // TODO: Discuss with Rob regarding the hardcoded value here causing dialogs opened on top of dialogs to appear behind the parent dialog
  z-index: 30000;
}

.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}

.pac-container {
  z-index: 40000;
}
