// icon-sizes
:root {
  // ToDo: refactor, sizes do not make sense
  // https://remberg.atlassian.net/browse/DES-375?atlOrigin=eyJpIjoiOWI0MDI5MTQyNDQyNDg1Nzk0ZjEyZTNmZTYwZTEyZWQiLCJwIjoiaiJ9
  --xxSmallIcon: 12px;
  --xSmallIcon: 14px;
  --smallIcon: 16px;
  --mediumIcon: 18px;
  --largeIcon: 20px;
  --xLargeIcon: 21px;
  --defaultIcon: 24px;
  --xxLargeIcon: 64px;
  --xxxLargeIcon: 128px;

  // ToDo: refactor & check if necessary
  // https://remberg.atlassian.net/browse/DES-375?atlOrigin=eyJpIjoiOWI0MDI5MTQyNDQyNDg1Nzk0ZjEyZTNmZTYwZTEyZWQiLCJwIjoiaiJ9
  // Default icon size
  --icon-24px: 24px;

  // Other Icons
  --icon-32px: 32px;
  --icon-34px: 34px;
  --icon-50px: 50px;
  --icon-40px: 40px;
  --icon-42px: 42px;
  --icon-60px: 60px;
  --icon-64px: 64px;
  --icon-140px: 140px;
}

// --xxSmallIcon: 12px;
@mixin xx-small-icon {
  font-size: var(--xxSmallIcon);
  height: var(--xxSmallIcon);
  width: var(--xxSmallIcon);
  min-width: var(--xxSmallIcon);
  line-height: var(--xxSmallIcon);
}
.mat-icon.xx-small-icon {
  @include xx-small-icon;
}

// --xSmallIcon: 14px;
@mixin x-small-icon {
  font-size: var(--xSmallIcon);
  height: var(--xSmallIcon);
  width: var(--xSmallIcon);
  min-width: var(--xSmallIcon);
  line-height: var(--xSmallIcon);
}
.mat-icon.x-small-icon {
  @include x-small-icon;
}

//--smallIcon: 16px;
@mixin small-icon {
  font-size: var(--smallIcon);
  height: var(--smallIcon);
  width: var(--smallIcon);
  min-width: var(--smallIcon);
  line-height: var(--smallIcon);
}
.mat-icon.small-icon,
.mat-mdc-list-base .mat-mdc-list-item .mat-mdc-list-item-icon.small-icon {
  @include small-icon;
}

// --mediumIcon: 18px;
@mixin medium-icon {
  font-size: var(--mediumIcon);
  height: var(--mediumIcon);
  width: var(--mediumIcon);
  min-width: var(--mediumIcon);
  line-height: var(--mediumIcon);
}
.mat-icon.medium-icon {
  @include medium-icon;
}

// --largeIcon: 20px;
@mixin large-icon {
  font-size: var(--largeIcon);
  height: var(--largeIcon);
  width: var(--largeIcon);
  min-width: var(--largeIcon);
  line-height: var(--largeIcon);
}
.mat-icon.large-icon {
  @include large-icon;
}
.smaller-icon {
  // ToDo: refactor this
  font-size: var(--largeIcon) !important;
  height: var(--largeIcon) !important;
  width: var(--largeIcon) !important;
  min-width: var(--largeIcon) !important;
}

// --xLargeIcon: 21px;
@mixin x-large-icon {
  font-size: var(--xLargeIcon);
  height: var(--xLargeIcon);
  width: var(--xLargeIcon);
  min-width: var(--xLargeIcon);
  line-height: var(--xLargeIcon);
}
.mat-icon.x-large-icon {
  @include x-large-icon;
}

// --xxLargeIcon: 64px;
@mixin xx-large-icon {
  font-size: var(--xxLargeIcon);
  height: var(--xxLargeIcon);
  width: var(--xxLargeIcon);
  min-width: var(--xxLargeIcon);
  line-height: var(--xxLargeIcon);
}
.mat-icon.xx-large-icon,
.mat-icon.preview-mat-icon-64,
.preview.mat-icon-64 {
  @include xx-large-icon;
}

// --xxLargeIcon: 128px;
@mixin xx-large-icon {
  font-size: var(--xxxLargeIcon);
  height: var(--xxxLargeIcon);
  width: var(--xxxLargeIcon);
  min-width: var(--xxxLargeIcon);
  line-height: var(--xxxLargeIcon);
}
.mat-icon.xxx-large-icon {
  @include xx-large-icon;
}

// Custom Icons & Theme
.mat-icon {
  color: var(--iconColor);
}

// ToDo: refactor this
// https://remberg.atlassian.net/browse/DES-375?atlOrigin=eyJpIjoiOWI0MDI5MTQyNDQyNDg1Nzk0ZjEyZTNmZTYwZTEyZWQiLCJwIjoiaiJ9
.mat-icon.preview-mat-icon {
  font-size: var(--icon-40px);
  height: var(--icon-40px);
  width: var(--icon-40px);
  min-width: var(--icon-40px);
}

// ToDo: refactor this
// https://remberg.atlassian.net/browse/DES-375?atlOrigin=eyJpIjoiOWI0MDI5MTQyNDQyNDg1Nzk0ZjEyZTNmZTYwZTEyZWQiLCJwIjoiaiJ9
.close-icon {
  font-size: var(--smallIcon);
  height: var(--smallIcon);
  width: var(--smallIcon);
  min-width: var(--smallIcon);
}

// ToDo: refactor this
// https://remberg.atlassian.net/browse/DES-375?atlOrigin=eyJpIjoiOWI0MDI5MTQyNDQyNDg1Nzk0ZjEyZTNmZTYwZTEyZWQiLCJwIjoiaiJ9
mat-icon.arrow-back {
  font-size: var(--icon-24px);
  height: var(--icon-24px);
  width: var(--icon-24px);
  min-width: var(--icon-24px);
}

.mat-step-icon-content > .mat-icon {
  color: var(--textColorOnPrimaryBackground) !important;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: var(--primaryThemeColor);
}

.mat-panel-title-icon {
  margin-right: 20px;
}

// ToDo: refactor this
// https://remberg.atlassian.net/browse/DES-375?atlOrigin=eyJpIjoiOWI0MDI5MTQyNDQyNDg1Nzk0ZjEyZTNmZTYwZTEyZWQiLCJwIjoiaiJ9
.application-file-icon {
  height: 20px;
  width: 20px;
}

.application-file-icon > svg {
  fill: var(--iconColor);
}

mat-icon.arrow-back {
  margin-right: 10px;
}

mat-icon.arrow-back:hover {
  cursor: pointer;
}

.icon-value-addition {
  margin-left: 5px;
}

// ToDo: refactor this
// https://remberg.atlassian.net/browse/DES-375?atlOrigin=eyJpIjoiOWI0MDI5MTQyNDQyNDg1Nzk0ZjEyZTNmZTYwZTEyZWQiLCJwIjoiaiJ9
.mat-icon.preview-mat-icon-60 {
  font-size: var(--icon-60px);
  height: var(--icon-60px);
  width: var(--icon-60px);
  min-width: var(--icon-60px);
}

/* Rotation */
.rotate-right-icon {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.rotate-left-icon {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.remberg-ui-map-icons.svg-size {
  svg {
    width: 40px;
    height: 40px;
    object-fit: contain;
  }
}
