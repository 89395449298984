/* Dialog */
.mat-mdc-dialog-container {
  --mdc-dialog-container-color: var(--white);
  --mdc-dialog-subhead-color: var(--black);
  --mdc-dialog-supporting-text-color: var(--black);
}

.dialog-wrapper-mat-dialog .mat-mdc-dialog-container {
  padding: 0px;

  .mdc-dialog__surface {
    padding: 0px;
  }
}

.mat-calendar-body-cell,
.mat-calendar-body-cell:last-child,
.mat-calendar-body-label {
  border-top: initial !important;
  border-right: initial !important;
  border-bottom: initial !important;
  border-left: initial !important;
}
