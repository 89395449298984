// Used for the notifications mat-menu
.notification-panel {
  width: 100vw !important;
  max-width: 400px !important;
  height: 100vh !important;
  max-height: 500px !important;
  overflow: hidden !important;
}
.notification-panel .mat-mdc-menu-content:not(:empty) {
  padding: 0px !important;
  height: 100% !important;
}
