//TODO: set proper styling for snack-bar
.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: var(--black) !important;
  --mdc-snackbar-supporting-text-color: var(-grey20) !important;
  --mat-snack-bar-button-color: var(--black) !important;
}

.mat-mdc-snack-bar-container .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
  --mat-snack-bar-button-color: var(--white) !important;
}
