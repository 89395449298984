$sizing: 14px;

app-metadata-section {
  .mat-mdc-standard-chip.preview-indicator-chip img {
    width: $sizing !important;
    height: $sizing !important;
    min-width: $sizing !important;
    margin-right: 0 !important;
  }
  .chip-text {
    font-size: var(--font-size-10px) !important;
  }
}
