.new-section {
  margin-top: 50px;
  margin-bottom: 20px;
}

.settings-section {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.settings-section-responsive {
  flex-direction: column;
}

.settings-section-left-side > h3 {
  margin-top: 0;
}

.settings-section-left-side {
  width: 33%;
  max-width: 300px;
  margin-right: 56px;
}

.settings-section-left-side-responsive {
  margin-right: initial;
  width: 100%;
  max-width: initial;
}

.settings-section-right-side {
  width: 100%;
  margin-top: 0;
  padding-top: 0;
  flex-direction: column;
}

.page-subtitle-not-white-background {
  opacity: 0.54;
}
