/* Cards */
mat-panel-title {
  overflow: hidden;

  .mat-headline-6 {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

mat-card-actions.mat-mdc-card-actions {
  margin: 0px;
}

mat-card.mat-mdc-card {
  padding: 24px;
  --mdc-elevated-card-container-shape: var(--borderRadius);
  --mdc-outlined-card-container-shape: var(--borderRadius);

  .mat-mdc-card-header,
  .mat-mdc-card-content {
    padding: 0px;
  }
}

mat-card.mat-mdc-card > mat-card-actions.mat-mdc-card-actions:last-child {
  margin: 0;
}

.login-card > .mat-mdc-card-header-custom > div,
mat-card.mat-mdc-card > .mat-mdc-card-header > div {
  margin: 0px;
}

mat-card.mat-mdc-card > mat-card-actions.mat-mdc-card-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

mat-card.mat-mdc-card > mat-card-actions.mat-mdc-card-actions:last-child {
  margin-bottom: 0px !important;
}

.card {
  border-radius: var(--borderRadius) !important;
  border: 1px solid var(--borderColor) !important;
  box-shadow: none !important;
}

.mat-mdc-card:not([class*='mat-elevation-z']) {
  border-radius: var(--borderRadius);
}

/* Message Card */
mat-card.mat-mdc-card.message-card,
.message-card {
  border: 1px solid var(--borderColor);
  width: 100%;
  padding: 12px 24px;
}

.message-card-highlight,
.dropzone.message-card-highlight,
mat-card.mat-mdc-card.message-card-highlight {
  border-color: var(--primaryThemeColor);
}

.card.is-clickable:hover,
tr.is-clickable:hover {
  background-color: var(--cardHoverColor);
}

.mat-mdc-card-header {
  .mat-mdc-card-avatar,
  .mat-mdc-card-avatar img.mat-mdc-card-avatar,
  .mat-mdc-card-avatar .mat-card-avatar {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    flex-shrink: 0;
    margin-bottom: 0;
    object-fit: cover;
  }
}
