@use 'theme/breakpoints' as breakpoints;

/* Toastr*/
.toast-container .ngx-toastr {
  display: flex;
  background-color: initial;
  padding: 0px;
  color: var(--black);
  // mat-elevation-z4
  box-shadow:
    0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%),
    0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.toast-container .ngx-toastr:hover {
  // mat-elevation-z6
  box-shadow:
    0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%),
    0px 1px 18px 0px rgb(0 0 0 / 12%);
}

@media #{breakpoints.$mat-lt-sm} {
  .toast-container .ngx-toastr {
    width: 100%;
    margin-top: 10px;
  }

  .toast-container.toast-bottom-right {
    left: 12px;
  }
}
