.mat-tree {
  background: transparent; /*
 * This padding sets alignment of the nested nodes.
 */
  .mat-nested-tree-node div[role='group'] {
    padding-left: 40px;
  }
  ul,
  li {
    margin-top: 0;
    margin-bottom: 0;
    list-style-type: none;
  }
  /*
   * Padding for leaf nodes.
   * Leaf nodes need to have padding so as to align with other non-leaf nodes
   * under the same parent.
   */
  div[role='group'] > .mat-tree-node {
    padding-left: 40px;
  }
}
.mat-tree-invisible {
  display: none;
}
