/* Datepicker */
.mat-calendar-body-selected {
  background-color: var(--primaryThemeColor) !important;
  color: var(--textColorOnPrimaryBackground);
}

.mat-datepicker-toggle-active {
  color: var(--primaryThemeColor) !important;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ),
.cdk-keyboard-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ),
.cdk-program-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
    .mat-calendar-body-comparison-identical
  ) {
  color: var(--textColorOnPrimaryBackground);
}
