/* Spacing */
.no-margin {
  margin: 0;
}

.no-padding {
  padding: 0;
}

/* Widths */
/* https://tailwindcss.com/docs/width#percentage-widths - for now % values only */
.w-0 {
  width: 0;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.333333%;
}

.w-1\/4 {
  width: 25%;
}

.w-1\/5 {
  width: 20%;
}

.w-1\/6 {
  width: 16.666667%;
}

.w-2\/3 {
  width: 66.666667%;
}

.w-2\/5 {
  width: 40%;
}

.w-3\/4 {
  width: 75%;
}

.w-3\/5 {
  width: 60%;
}

.w-4\/5 {
  width: 80%;
}

.w-5\/6 {
  width: 83.333333%;
}

.w-\[150\%\] {
  width: 150%;
}

.w-\[216\%\] {
  width: 216%;
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

/* https://tailwindcss.com/docs/height - for now % values only */
.h-0 {
  height: 0;
}

.h-1\/2 {
  height: 50%;
}

.h-1\/3 {
  height: 33.333333%;
}

.h-1\/4 {
  height: 25%;
}

.h-1\/5 {
  height: 20%;
}

.h-1\/6 {
  height: 16.666667%;
}

.h-2\/3 {
  height: 66.666667%;
}

.h-2\/5 {
  height: 40%;
}

.h-3\/4 {
  height: 75%;
}

.h-3\/5 {
  height: 60%;
}

.h-4\/5 {
  height: 80%;
}

.h-5\/6 {
  height: 83.333333%;
}

.h-\[150\%\] {
  height: 150%;
}

.w-\[216\%\] {
  height: 216%;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.width-250 {
  width: 250px;
  min-width: 250px;
  display: block;
}

.width-300 {
  width: 300px;
  min-width: 300px;
}

.width-300 {
  width: 350px;
  min-width: 350px;
}

.width-400 {
  width: 400px;
}

.width-500 {
  width: 500px;
}
