/* remberg Cards */
.rb-card {
  .content,
  .footer-content,
  .sub-title-content {
    max-width: 100%;
  }
  .row {
    display: flex;
    min-height: 30px;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}

/* Global Map Cards (deprecated) */
.map-card-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 300px;
  gap: 10px;
  min-height: 60px;

  .loading-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    margin: auto;
  }

  .card-content-column {
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;

    .open-icon,
    .is-clickable {
      position: absolute;
      right: 10px;
      top: 13px;
      font-size: var(--font-size-14px);
      min-width: var(--font-size-14px);
      width: var(--font-size-14px);
      height: var(--font-size-14px);
    }

    .card-content-body {
      display: flex;
      flex-direction: column;
      gap: 5px;
      font-size: var(--font-size-12px) !important;
      line-height: var(--line-height-20px) !important;

      .indicator .chip-text {
        font-size: var(--font-size-12px) !important;
      }

      .display-inline {
        gap: 0px !important;
      }

      .empty-div {
        margin-left: -5px;
      }

      .large-icon {
        font-size: var(--font-size-14px);
        background: var(--grey100);
        border-color: var(--borderColor);
        border-radius: 50px;
        color: var(--grey800);
        border: 1px solid;
        min-width: 18px;
        width: 18px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-color: var(--borderColor);
      }
    }
  }

  h4 {
    margin: 0;
    font-size: var(--font-size-14px);
    line-height: var(--line-height-18px);
    font-weight: var(--font-weight-bold);
    margin-bottom: 2px;
    max-width: calc(100% - 20px);
    min-width: calc(100% - 20px);
  }

  p {
    margin: 0;
  }

  .icon-wrapper {
    background: var(--paletteBlackLight900);
    border-radius: 20px;
    border: 1px solid var(--borderColor);
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      font-size: var(--font-size-24px);
      height: var(--font-size-24px);
      width: var(--font-size-24px);
    }
  }

  .preview-wrapper {
    border-radius: 20px;
    border: 1px solid var(--borderColor);
    width: 40px;
    height: 40px;
  }
}
