/* Landing & Login Styles */
.landing-card,
.login-card {
  max-width: 500px;

  .mat-mdc-fab.mat-primary.landing-button,
  .mat-mdc-unelevated-button.mat-primary.landing-button,
  .mat-mdc-mini-fab.mat-primary.landing-button,
  .mat-mdc-raised-button.mat-primary.landing-button {
    margin-top: 5px;
    width: 100%;
  }

  .text-link {
    font-size: var(--font-size-14px);
    text-decoration: underline;
  }

  .mat-mdc-card-header .mat-mdc-card-title {
    margin-bottom: 20px;
  }

  .divider.mat-divider-horizontal,
  .mat-mdc-card > .mat-divider-horizontal {
    position: initial;
    margin: 20px 0px;
  }

  .mat-mdc-card-actions {
    padding: 0px;
  }
}

.landing {
  background-color: var(--backgroundThemeColor);
  height: 100%;
}

.landing-logo-wrapper {
  padding-top: 100px;
  padding-bottom: 50px;
}

.landing-logo-wrapper-small,
.padded.landing-logo-wrapper-small {
  padding-top: calc(60px + env(safe-area-inset-top));
  padding-bottom: 30px;
}

.landing-logo-image {
  display: block;
  max-height: 150px;
  max-width: 350px;
  margin: auto;
}

.landing-logo-image-small {
  width: initial;
  max-width: 280px;
  max-height: 120px;
  margin: auto;
}

.landing-button-inline {
  padding: 4px 25px !important;
}

.landing-button-loader {
  margin-top: 15px;
  font-size: var(--font-size-17px) !important;
}

.landing-button-loader-inline {
  padding: 0 25px !important;
}
