/* Color Picker */
color-picker > .color-picker {
  box-shadow:
    0 2px 1px -1px rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  display: block;
  border-radius: var(--borderRadius);
  border: 1px solid var(--alternategrey);

  input,
  .hex-text .box input {
    border: 1px solid var(--borderColor);
    border-radius: var(--borderRadius);
    color: var(--black);
  }

  .color-picker-buttons {
    margin-left: 20px;
    margin-top: 10px;
  }
}
