.mat-expansion-panel {
  --mat-expansion-container-shape: var(--borderRadius);
}

.mat-expansion-panel:not([class*='mat-elevation-z']) {
  box-shadow: none !important;
  border: 1px solid var(--borderColor);
  --mat-expansion-container-background-color: var(--white);
}

.mat-expansion-panel:not([class*='mat-elevation-z']).mat-expansion-panel-no-card {
  --mat-expansion-container-background-color: transparent;
  border: 0;
}

.mat-expansion-panel.no-border {
  box-shadow: none !important;
  border: 0px solid transparent !important;
  border-radius: 0px !important;
  background: initial !important;
  box-shadow: inherit !important;
}

.mat-accordion-no-card {
  width: 100% !important;
}

.mat-accordion-no-card > .mat-expansion-panel-no-card,
.mat-expansion-panel-no-card {
  border: 0px !important;
  margin: 0 -25px;
  box-shadow: none !important;
}

.mat-accordion-no-card > .mat-expansion-panel-no-card > .mat-expansion-panel-header {
  padding-right: 30px;
}

.mat-expansion-panel-no-card {
  .mat-expanion-panel-header-title {
    margin-right: 5px !important;
  }

  .mat-expanion-panel-header-title,
  .mat-expanion-panel-header-title .mat-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  .mdc-button__label {
    text-wrap: nowrap;
  }
}

.mat-accordion-no-card,
.mat-expansion-panel-no-card,
.mat-expansion-panel.no-border {
  --mat-expansion-container-shape: 0;
  .mat-expansion-panel-header {
    padding: 0px 20px !important;
  }
  .mat-expansion-panel-content .mat-expansion-panel-body {
    padding: 0 20px 16px 20px !important;
  }
  .mat-expansion-panel-header-title,
  .mat-expansion-panel-header-description {
    margin-right: 5px;
  }
}

.mat-expansion-panel-header-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  flex-grow: 2 !important;
  h3 {
    margin-bottom: 0;
  }
}

.mat-expansion-panel-body p {
  margin: 0;
}

.mat-expansion-indicator::after {
  margin-top: -6px !important;
}

.mat-expansion-panel-header-description {
  justify-content: flex-end;
  flex-grow: 1 !important;
}

.mat-expansion-panel-content {
  --mat-expansion-container-text-font: inherit;
  --mat-expansion-container-text-weight: var(--font-size-regular);
}
