.loader-container {
  position: relative;
  height: 80px;
  width: 100%;
}

.mat-icon-spin {
  -webkit-animation: mat-icon-spin 2s infinite linear;
  -webkit-animation-direction: reverse;
  animation: mat-icon-spin 2s infinite linear;
  animation-direction: reverse;
}

@-webkit-keyframes mat-icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes mat-icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

/* Loader */
.ngx-progress-bar {
  color: var(--primaryThemeColor) !important;
}

.loader-container-relative {
  position: relative;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 3px;
  border: 3px solid var(--primaryThemeColor);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--primaryThemeColor) transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
