@use 'theme/breakpoints' as breakpoints;

/* Main Pages Mobile */
.bottom-padding-mobile {
  padding-bottom: 96px;
}

.absolute-host {
  position: absolute;
  width: calc(100% - 40px);
}

/* Readonly Pages Mobile */
.readonly-ionic-subject {
  font-size: var(--font-size-16px);
  font-weight: var(--font-weight-bold);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: initial;
  width: 100%;
}

.readonly-ionic-section {
  padding: 10px 0;
  display: flex;
  flex-direction: column;

  .field-row {
    margin: 10px 0;
    display: grid;

    @media #{breakpoints.$mat-gt-xs} {
      grid-template-columns: 40% minmax(0, 1fr);
    }

    label {
      font-size: var(--font-size-14px);
      margin-bottom: 10px;
      font-weight: var(--font-weight-bold);
      color: var(--black);
    }

    .preview-link-list {
      display: flex;
      flex-direction: row;
      gap: 8px;
      flex-wrap: wrap;
      align-items: center;
    }

    .address {
      line-height: initial;
      display: flex;
      align-items: center;
      margin-bottom: 3px;
      font-size: var(--font-size-14px);
    }
  }
}
