/* stylelint-disable */

/* Color Variables */
:root {
  --palettePrimary: #facc2e;
  --palettePrimaryDark100: #e1b829;
  --palettePrimaryDark200: #c8a325;
  --palettePrimaryDark300: #af8f20;
  --palettePrimaryDark400: #967a1c;
  --palettePrimaryDark500: #7d6617;
  --palettePrimaryDark600: #645212;
  --palettePrimaryDark700: #4b3d0e;
  --palettePrimaryDark800: #322909;
  --palettePrimaryDark900: #191405;
  --palettePrimaryLight100: #fbd143;
  --palettePrimaryLight200: #fbd658;
  --palettePrimaryLight300: #fcdb6d;
  --palettePrimaryLight400: #fce082;
  --palettePrimaryLight500: #fde697;
  --palettePrimaryLight600: #fdebab;
  --palettePrimaryLight700: #fef0c0;
  --palettePrimaryLight800: #fef5d5;
  --palettePrimaryLight900: #fffaea;

  --paletteWarn: #ff0000;
  --paletteWarnDark100: #e60000;
  --paletteWarnDark200: #cc0000;
  --paletteWarnDark300: #b30000;
  --paletteWarnDark400: #990000;
  --paletteWarnDark500: #800000;
  --paletteWarnDark600: #660000;
  --paletteWarnDark700: #4c0000;
  --paletteWarnDark800: #330000;
  --paletteWarnLight100: #ff1a1a;
  --paletteWarnLight200: #ff3333;
  --paletteWarnLight300: #ff4d4d;
  --paletteWarnLight400: #ff6666;
  --paletteWarnLight500: #ff8080;
  --paletteWarnLight600: #ff9999;
  --paletteWarnLight700: #ffb3b3;
  --paletteWarnLight800: #ffcccc;

  --paletteWhite: #ffffff;
  --paletteBlack: #30343f;
  --paletteBlackDark100: #2b2f39;
  --paletteBlackDark200: #262a32;
  --paletteBlackDark300: #22242c;
  --paletteBlackDark400: #1d1f26;
  --paletteBlackDark500: #181a20;
  --paletteBlackDark600: #131519;
  --paletteBlackDark700: #0e1013;
  --paletteBlackDark800: #0a0a0d;
  --paletteBlackDark900: #050506;
  --paletteBlackDark1000: #000000;
  --paletteBlackLight100: #454852;
  --paletteBlackLight200: #595d65;
  --paletteBlackLight300: #6e7179;
  --paletteBlackLight400: #83858c;
  --paletteBlackLight500: #989a9f;
  --paletteBlackLight600: #acaeb2;
  --paletteBlackLight700: #c1c2c5;
  --paletteBlackLight800: #d6d6d9;
  --paletteBlackLight900: #eaebec;
  --paletteBlackLight925: #f1f1f2;
  --paletteBlackLight950: #f7f7f7;
  --paletteBlackLight975: #fafafa;
  --paletteBlackLight980: #fcfcfc;
  --paletteBlackLight985: #fefefe;
  --paletteBlackLight1000: #ffffff;

  --paletteYellow: #f0d042;
  --paletteYellowDark100: #d8bb3b;
  --paletteYellowDark200: #c0a635;
  --paletteYellowDark300: #a8922e;
  --paletteYellowDark400: #907d28;
  --paletteYellowDark500: #786821;
  --paletteYellowDark600: #60531a;
  --paletteYellowDark700: #483e14;
  --paletteYellowDark800: #302a0d;
  --paletteYellowDark900: #181507;
  --paletteYellowLight100: #f2d555;
  --paletteYellowLight200: #f3d968;
  --paletteYellowLight300: #f5de7b;
  --paletteYellowLight400: #f6e38e;
  --paletteYellowLight500: #f8e8a1;
  --paletteYellowLight600: #f9ecb3;
  --paletteYellowLight700: #fbf1c6;
  --paletteYellowLight800: #fcf6d9;
  --paletteYellowLight900: #fefaec;

  --paletteOrange: #ed9426;
  --paletteOrangeDark100: #d58522;
  --paletteOrangeDark200: #be761e;
  --paletteOrangeDark300: #a6681b;
  --paletteOrangeDark400: #8e5917;
  --paletteOrangeDark500: #774a13;
  --paletteOrangeDark600: #5f3b0f;
  --paletteOrangeDark700: #472c0b;
  --paletteOrangeDark800: #2f1e08;
  --paletteOrangeDark900: #180f04;
  --paletteOrangeLight100: #ef9f3c;
  --paletteOrangeLight200: #f1a951;
  --paletteOrangeLight300: #f2b467;
  --paletteOrangeLight400: #f4bf7d;
  --paletteOrangeLight500: #f6ca93;
  --paletteOrangeLight600: #f8d4a8;
  --paletteOrangeLight700: #fadfbe;
  --paletteOrangeLight800: #fbead4;
  --paletteOrangeLight900: #fdf4e9;

  --paletteRed: #f5403d;
  --paletteRedDark100: #dd3a37;
  --paletteRedDark200: #c43331;
  --paletteRedDark300: #ac2d2b;
  --paletteRedDark400: #932625;
  --paletteRedDark500: #7b201f;
  --paletteRedDark600: #621a18;
  --paletteRedDark700: #491312;
  --paletteRedDark800: #310d0c;
  --paletteRedDark900: #180606;
  --paletteRedLight100: #f65350;
  --paletteRedLight200: #f76664;
  --paletteRedLight300: #f87977;
  --paletteRedLight400: #f98c8b;
  --paletteRedLight500: #faa09e;
  --paletteRedLight600: #fbb3b1;
  --paletteRedLight700: #fcc6c5;
  --paletteRedLight800: #fdd9d8;
  --paletteRedLight900: #feecec;

  --palettePink: #e91e63;
  --palettePinkDark100: #d21b59;
  --palettePinkDark200: #ba184f;
  --palettePinkDark300: #a31545;
  --palettePinkDark400: #8c123b;
  --palettePinkDark500: #750f32;
  --palettePinkDark600: #5d0c28;
  --palettePinkDark700: #46091e;
  --palettePinkDark800: #2f0614;
  --palettePinkDark900: #17030a;
  --palettePinkLight100: #eb3573;
  --palettePinkLight200: #ed4b82;
  --palettePinkLight300: #f06292;
  --palettePinkLight400: #f278a1;
  --palettePinkLight500: #f48fb1;
  --palettePinkLight600: #f6a5c1;
  --palettePinkLight700: #f8bcd0;
  --palettePinkLight800: #fbd2e0;
  --palettePinkLight900: #fde9ef;

  --paletteGreen: #2abb32;
  --paletteGreenDark100: #26a82d;
  --paletteGreenDark200: #229628;
  --paletteGreenDark300: #1d8323;
  --paletteGreenDark400: #19701e;
  --paletteGreenDark500: #155e19;
  --paletteGreenDark600: #114b14;
  --paletteGreenDark700: #0d380f;
  --paletteGreenDark800: #08250a;
  --paletteGreenDark900: #041305;
  --paletteGreenLight100: #3fc247;
  --paletteGreenLight200: #55c95b;
  --paletteGreenLight300: #6acf70;
  --paletteGreenLight400: #7fd684;
  --paletteGreenLight500: #95dd99;
  --paletteGreenLight600: #aae4ad;
  --paletteGreenLight700: #bfebc2;
  --paletteGreenLight800: #d4f1d6;
  --paletteGreenLight900: #eaf8eb;

  --paletteCyan: #26c6da;
  --paletteCyanDark100: #22b2c4;
  --paletteCyanDark200: #1e9eae;
  --paletteCyanDark300: #1b8b99;
  --paletteCyanDark400: #177783;
  --paletteCyanDark500: #13636d;
  --paletteCyanDark600: #0f4f57;
  --paletteCyanDark700: #0b3b41;
  --paletteCyanDark800: #08282c;
  --paletteCyanDark900: #041416;
  --paletteCyanLight100: #3cccde;
  --paletteCyanLight200: #51d1e1;
  --paletteCyanLight300: #67d7e5;
  --paletteCyanLight400: #7ddde9;
  --paletteCyanLight500: #93e3ed;
  --paletteCyanLight600: #a8e8f0;
  --paletteCyanLight700: #beeef4;
  --paletteCyanLight800: #d4f4f8;
  --paletteCyanLight900: #e9f9fb;

  --paletteBlue: #1874ec;
  --paletteBlueDark100: #1668d4;
  --paletteBlueDark200: #135dbd;
  --paletteBlueDark300: #1151a5;
  --paletteBlueDark400: #0e468e;
  --paletteBlueDark500: #0c3a76;
  --paletteBlueDark600: #0a2e5e;
  --paletteBlueDark700: #072347;
  --paletteBlueDark800: #05172f;
  --paletteBlueDark900: #020c18;
  --paletteBlueLight100: #2f82ee;
  --paletteBlueLight200: #4690f0;
  --paletteBlueLight300: #5d9ef2;
  --paletteBlueLight400: #74acf4;
  --paletteBlueLight500: #8cbaf6;
  --paletteBlueLight600: #a3c7f7;
  --paletteBlueLight700: #bad5f9;
  --paletteBlueLight800: #d1e3fb;
  --paletteBlueLight900: #e8f1fd;

  --paletteBrightBlue: #439cdf;
  --paletteBrightBlueDark100: #3c8cc9;
  --paletteBrightBlueDark200: #367db2;
  --paletteBrightBlueDark300: #2f6d9c;
  --paletteBrightBlueDark400: #285e86;
  --paletteBrightBlueDark500: #224e70;
  --paletteBrightBlueDark600: #1b3e59;
  --paletteBrightBlueDark700: #142f43;
  --paletteBrightBlueDark800: #0d1f2d;
  --paletteBrightBlueDark900: #071016;
  --paletteBrightBlueLight100: #56a6e2;
  --paletteBrightBlueLight200: #69b0e5;
  --paletteBrightBlueLight300: #7bbae9;
  --paletteBrightBlueLight400: #8ec4ec;
  --paletteBrightBlueLight500: #a1ceef;
  --paletteBrightBlueLight600: #b4d7f2;
  --paletteBrightBlueLight700: #c7e1f5;
  --paletteBrightBlueLight800: #d9ebf9;
  --paletteBrightBlueLight900: #ecf5fc;

  --paletteBrown: #795548;
  --paletteBrownDark100: #6d4d41;
  --paletteBrownDark200: #61443a;
  --paletteBrownDark300: #553b32;
  --paletteBrownDark400: #49332b;
  --paletteBrownDark500: #3d2b24;
  --paletteBrownDark600: #30221d;
  --paletteBrownDark700: #241916;
  --paletteBrownDark800: #18110e;
  --paletteBrownDark900: #0c0807;
  --paletteBrownLight100: #86665a;
  --paletteBrownLight200: #94776d;
  --paletteBrownLight300: #a1887f;
  --paletteBrownLight400: #af9991;
  --paletteBrownLight500: #bcaaa4;
  --paletteBrownLight600: #c9bbb6;
  --paletteBrownLight700: #d7ccc8;
  --paletteBrownLight800: #e4ddda;
  --paletteBrownLight900: #f2eeed;

  --paletteBrightYellow: #ffe924;
  --paletteBrightYellowDark100: #e6d220;
  --paletteBrightYellowDark200: #ccba1d;
  --paletteBrightYellowDark300: #b3a319;
  --paletteBrightYellowDark400: #998c16;
  --paletteBrightYellowDark500: #807512;
  --paletteBrightYellowDark600: #665d0e;
  --paletteBrightYellowDark700: #4c460b;
  --paletteBrightYellowDark800: #332f07;
  --paletteBrightYellowDark900: #191704;
  --paletteBrightYellowLight100: #ffeb3a;
  --paletteBrightYellowLight200: #ffed50;
  --paletteBrightYellowLight300: #fff066;
  --paletteBrightYellowLight400: #fff27c;
  --paletteBrightYellowLight500: #fff492;
  --paletteBrightYellowLight600: #fff6a7;
  --paletteBrightYellowLight700: #fff8bd;
  --paletteBrightYellowLight800: #fffbd3;
  --paletteBrightYellowLight900: #fffde9;

  --paletteBrightOrange: #ffae00;
  --paletteBrightOrangeDark100: #e69d00;
  --paletteBrightOrangeDark200: #cc8b00;
  --paletteBrightOrangeDark300: #b37a00;
  --paletteBrightOrangeDark400: #996800;
  --paletteBrightOrangeDark500: #805700;
  --paletteBrightOrangeDark600: #664600;
  --paletteBrightOrangeDark700: #4c3400;
  --paletteBrightOrangeDark800: #332300;
  --paletteBrightOrangeDark900: #191100;
  --paletteBrightOrangeLight100: #ffb61a;
  --paletteBrightOrangeLight200: #ffbe33;
  --paletteBrightOrangeLight300: #ffc64d;
  --paletteBrightOrangeLight400: #ffce66;
  --paletteBrightOrangeLight500: #ffd780;
  --paletteBrightOrangeLight600: #ffdf99;
  --paletteBrightOrangeLight700: #ffe7b3;
  --paletteBrightOrangeLight800: #ffefcc;
  --paletteBrightOrangeLight900: #fff7e6;

  --paletteBrightRed: #bb0202;
  --paletteBrightRedDark100: #a80202;
  --paletteBrightRedDark200: #960202;
  --paletteBrightRedDark300: #830101;
  --paletteBrightRedDark400: #700101;
  --paletteBrightRedDark500: #5e0101;
  --paletteBrightRedDark600: #4b0101;
  --paletteBrightRedDark700: #380101;
  --paletteBrightRedDark800: #250000;
  --paletteBrightRedDark900: #130000;
  --paletteBrightRedLight100: #c21b1b;
  --paletteBrightRedLight200: #c93535;
  --paletteBrightRedLight300: #cf4e4e;
  --paletteBrightRedLight400: #d66767;
  --paletteBrightRedLight500: #dd8181;
  --paletteBrightRedLight600: #e49a9a;
  --paletteBrightRedLight700: #ebb3b3;
  --paletteBrightRedLight800: #f1cccc;
  --paletteBrightRedLight900: #f8e6e6;

  --paletteBrightGreen: #37c200;
  --paletteBrightGreenDark100: #32af00;
  --paletteBrightGreenDark200: #2c9b00;
  --paletteBrightGreenDark300: #278800;
  --paletteBrightGreenDark400: #217400;
  --paletteBrightGreenDark500: #1c6100;
  --paletteBrightGreenDark600: #164e00;
  --paletteBrightGreenDark700: #103a00;
  --paletteBrightGreenDark800: #0b2700;
  --paletteBrightGreenDark900: #051300;
  --paletteBrightGreenLight100: #4bc81a;
  --paletteBrightGreenLight200: #5fce33;
  --paletteBrightGreenLight300: #73d44d;
  --paletteBrightGreenLight400: #87da66;
  --paletteBrightGreenLight500: #9be180;
  --paletteBrightGreenLight600: #afe799;
  --paletteBrightGreenLight700: #c3edb3;
  --paletteBrightGreenLight800: #d7f3cc;
  --paletteBrightGreenLight900: #ebf9e6;

  --palettePurple: #9c27b0;
  --palettePurpleDark100: #8c239e;
  --palettePurpleDark200: #7d1f8d;
  --palettePurpleDark300: #6d1b7b;
  --palettePurpleDark400: #5e176a;
  --palettePurpleDark500: #4e1458;
  --palettePurpleDark600: #3e1046;
  --palettePurpleDark700: #2f0c35;
  --palettePurpleDark800: #1f0823;
  --palettePurpleDark900: #100412;
  --palettePurpleLight100: #a63db8;
  --palettePurpleLight200: #b052c0;
  --palettePurpleLight300: #ba68c8;
  --palettePurpleLight400: #c47dd0;
  --palettePurpleLight500: #ce93d8;
  --palettePurpleLight600: #d7a9df;
  --palettePurpleLight700: #e1bee7;
  --palettePurpleLight800: #ebd4ef;
  --palettePurpleLight900: #f5e9f7;
}
