// font-family
:root {
  --roboto: Roboto, monospace;
  --material-icons: 'Material Icons';
  --material-icons-outlined: 'Material Icons Outlined';
}

// font-sizes
:root {
  --font-size-10px: 10px;
  --font-size-12px: 12px;
  --font-size-14px: 14px;
  --font-size-15px: 15px;
  --font-size-16px: 16px;
  --font-size-18px: 18px;
  --font-size-20px: 20px;
  --font-size-24px: 24px;

  // ToDo: refactor & check if needed
  // https://remberg.atlassian.net/browse/DES-375?atlOrigin=eyJpIjoiOWI0MDI5MTQyNDQyNDg1Nzk0ZjEyZTNmZTYwZTEyZWQiLCJwIjoiaiJ9
  --font-size-11px: 11px; // detail card
  --font-size-13px: 13px; // file-tree header table, common text
  --font-size-17px: 17px; // landing button text
  --font-size-20px: 20px; // small icon - title dialog x3 - asset status - headers - card title
  --font-size-21px: 21px; // activity icon
  --font-size-22px: 22px; // link, main text
  --font-size-28px: 28px; // image in dropdown
  --font-size-30px: 30px; // remberg start paragraph
  --font-size-32px: 32px; // cloud icon for uploads
  --font-size-40px: 40px; //for big icons in for example offline mode
  --font-size-56px: 56px; // for larger icons in the thumbnail component
  --font-size-70px: 70px;
  --font-size-80px: 80px; //for big icons in for example offline mode
  --font-size-status-circle-after: 20px;
  --font-size-mat-suffix: 0.875rem;
  --font-size-75-pc: 75%;
  --font-size-Dot75rem: 0.75rem;
  --font-unset: unset;
}

// font-weight
:root {
  --font-weight-regular: 400;
  --font-weight-bold: 500;
}

// line-height
:root {
  --line-height-14px: 14px;
  --line-height-18px: 18px;
  --line-height-20px: 20px;
  --line-height-24px: 24px;
  --line-height-28px: 28px;
  --line-height-30px: 30px;
  --line-height-32px: 32px;

  --line-height-1Dot125: 1.125; // .mat-input
  --line-height-normal: normal;
  --line-height-inherit: inherit;

  // ToDo: refactor & check if needed
  // https://remberg.atlassian.net/browse/DES-375?atlOrigin=eyJpIjoiOWI0MDI5MTQyNDQyNDg1Nzk0ZjEyZTNmZTYwZTEyZWQiLCJwIjoiaiJ9
  --line-height-1em: 1em;
  --line-height-1rem: 1rem;
  --line-height-11px: 11px;
  --line-height-12px: 12px;
  --line-height-15px: 15px;
  --line-height-16px: 16px;
  --line-height-21px: 21px;
  --line-height-22px: 22px;
  --line-height-26px: 26px;
  --line-height-27px: 27px;
  --line-height-31px: 31px; // activity feeds
  --line-height-32px: 32px;
  --line-height-36px: 36px;
  --line-height-40px: 40px;
  --line-height-42px: 42px;
  --line-height-48px: 48px;
  --line-height-56px: 56px;
}

// letter-spacing
:root {
  --letter-spacing-normal: normal;
}

// Content
@mixin mat-body {
  font-size: var(--font-size-14px);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-20px);
  font-family: var(--roboto);
  letter-spacing: var(--letter-spacing-normal);
}

@mixin mat-body-strong {
  font-size: var(--font-size-14px);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-20px);
  font-family: var(--roboto);
  letter-spacing: var(--letter-spacing-normal);
}

@mixin mat-body-small {
  font-size: var(--font-size-12px);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-18px);
  font-family: var(--roboto);
  letter-spacing: var(--letter-spacing-normal);
}

@mixin mat-body-tiny {
  font-size: var(--font-size-10px);
  line-height: var(--line-height-14px);
  font-family: var(--roboto);
  font-weight: var(--font-weight-regular);
  letter-spacing: var(--letter-spacing-normal);
}

html,
body,
p,
.mat-body,
.mat-typography .mat-body,
.mat-body-2,
.mat-typography {
  @include mat-body;
}

.mat-body-strong,
.mat-typography .mat-body-strong,
.mat-subtitle-2 {
  @include mat-body-strong;
}

.mat-body-small,
.mat-small,
.mat-typography .mat-small,
.mat-caption,
.mat-typography .mat-caption {
  @include mat-body-small;
}

.mat-small-bold-light {
  @include mat-body-small;
  font-weight: var(--font-weight-bold);
  color: var(--paletteBlackLight200);
}

.mat-body-tiny,
.mat-tiny,
.mat-mdc-tooltip {
  @include mat-body-tiny;
}

// Headlines
@mixin mat-h1 {
  font-size: var(--font-size-24px);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-32px);
  font-family: var(--roboto);
  letter-spacing: var(--letter-spacing-normal);
  margin: 0 0 16px;
}

@mixin mat-h2 {
  font-size: var(--font-size-20px);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-30px);
  font-family: var(--roboto);
  letter-spacing: var(--letter-spacing-normal);
  margin: 0 0 16px;
}

@mixin mat-h3 {
  font-size: var(--font-size-18px);
  font-weight: var(--font-weight-bold);
  line-height: var(--line-height-28px);
  font-family: var(--roboto);
  letter-spacing: var(--letter-spacing-normal);
  margin: 0 0 16px;
}

@mixin mat-h4 {
  font-size: var(--font-size-16px);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-24px);
  font-family: var(--roboto);
  letter-spacing: var(--letter-spacing-normal);
  margin: 0 0 16px;
}

@mixin mat-h5 {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-14px);
  line-height: var(--line-height-20px);
  font-family: var(--roboto);
  margin: 0 0 12px;
}

@mixin mat-h6 {
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-14px);
  line-height: var(--line-height-20px);
  font-family: var(--roboto);
  margin: 0 0 12px;
}

h1,
.mat-typography h1,
.mat-h1,
.mat-typography .mat-h1,
.mat-headline-5,
.mat-typography .mat-headline {
  @include mat-h1;
}

h2,
.mat-h2,
.mat-typography h2 .mat-h2,
.mat-typography .mat-h2,
.mat-headline-6,
.mat-typography .mat-title  {
  @include mat-h2;
}

h3,
.mat-typography h3,
.mat-h3,
.mat-typography .mat-h3,
.mat-subtitle-1,
.mat-typography .mat-subheading-2,
.mat-panel-title,
mat-panel-title .mat-headline-6,
.mat-expansion-panel-header-title,
.mat-mdc-card-header .mat-mdc-card-title {
  @include mat-h3;
}

.mat-panel-title,
mat-panel-title .mat-headline-6,
.mat-expansion-panel-header-title {
  margin: 0px;
}

h4,
.mat-typography h4,
.mat-h4,
.mat-typography .mat-h4,
.mat-body-1,
.mat-typography .mat-subheading-1 {
  @include mat-h4;
}

h5,
.mat-typography h5,
.mat-h5,
.mat-typography .mat-h5 {
  @include mat-h5;
}

h6,
.mat-typography h6,
.mat-h6,
.mat-typography .mat-h6 {
  @include mat-h6;
}

// Font Additions
@mixin mat-strong {
  font-weight: var(--font-weight-bold);
}

b,
strong {
  @include mat-strong;
}

/* Help Text */
.hint-text-x-small {
  font-size: var(--font-size-12px);
  line-height: var(--line-height-16px);
  margin-top: 0px;
  margin-bottom: 0px;
}

.hint-text-medium {
  font-size: var(--font-size-14px);
  line-height: var(--line-height-20px);
  margin-top: 0px;
  margin-bottom: 0px;
}

/* Fonts */
.font-weight-bold,
.status-font-weight-bold {
  font-weight: var(--font-weight-bold) !important;
}

a {
  color: inherit;
}
