.mat-mdc-slide-toggle {
  // --mat-slide-toggle-label-text-font: var(--roboto);
  // --mat-slide-toggle-label-text-size: var(--font-size-14px);
  // --mat-slide-toggle-label-text-tracking: normal;
  // --mat-slide-toggle-label-text-line-height: var(--line-height-20px);
  --mat-slide-toggle-label-text-weight: var(--font-weight-regular);
}

.mat-mdc-slide-toggle label {
  padding-left: 8px !important;
}
