@use 'breakpoints' as breakpoints;
@import '../styles/utils.scss';

$padding: var(--interfacePadding);
$negativePadding: var(--negativeInterfacePadding);

@mixin sticky-header {
  position: sticky;
  background-color: var(--backgroundColor);
  top: 0px;
  z-index: 2;
}

.detail {
  &-page {
    height: calc(100vh - (var(--mainNavigationToolbarHeight) + 2 * $padding));
  }
  &-page,
  &-sidebar {
    .mat-headline-5 {
      margin: 0;
      max-width: calc(100% - 30px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    :host-context(.inline-sidebar) {
      // Added div to increase specificity so that we can override width without important
      div.wrapper:not(.collapsed) {
        width: 300px;
      }
    }
    .properties-sidebar:not(.detail-sidebar),
    .associations-sidebar {
      app-sidebar {
        .wrapper:not(.collapsed) {
          width: 300px;
        }
      }
    }
    .detail-sidenav-container {
      display: flex;
      flex: 1;
      margin: $negativePadding $negativePadding $negativePadding 0px;
    }
  }
}

.middle-section-header-container,
.properties-sidebar {
  .middle-section-header,
  .properties-sidebar-header {
    flex: 1;
    min-width: 0px;

    .settings-buttons-container {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .settings-button {
      display: flex;
      justify-content: center;
      .mat-mdc-raised-button {
        display: flex;
        justify-content: center;
        color: var(--textColorOnPrimaryBackground);
        background-color: var(--primaryThemeColor);
      }
      .mdc-button__label {
        gap: 5px;
        display: flex;
        align-items: center;
      }
    }
  }
  .mat-headline-5 {
    line-height: var(--line-height-40px);
  }
  .header-container {
    display: flex;
    flex-direction: column;
    min-width: 0px;
    flex: 1;

    h1 {
      margin: 0;
    }
  }
  .heading {
    padding-bottom: $padding;
  }
  .menu {
    margin-left: auto;
  }
  .thumbnail {
    height: 80px;
    width: 80px;
    min-width: 80px;
    &-info {
      min-width: 0px;
      line-height: var(--line-height-24px);
      &-text {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow-x: hidden;
        ::ng-deep .value {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow-x: hidden;
        }
      }
    }
    &-container {
      gap: 16px;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: $padding;

    .thumbnail {
      flex: 0;
    }

    .thumbnail-info {
      flex: 1;
    }
  }
}

.middle-section-header-container {
  justify-content: center;
  align-items: space-between;
  flex-direction: column;
  display: flex;
  padding-left: $padding;
  padding-right: $padding;
  background-color: var(--white);

  &-first {
    padding-top: $padding;
  }
  &-last {
    padding-bottom: $padding;
    border-bottom: 1px solid var(--borderColor);
  }

  .settings-button {
    flex: 1;
  }
}

.middle-section,
.middle-section-mobile {
  display: flex;
  &:not(&-mobile) {
    padding: 0 $padding;
    margin-top: $padding;
    margin-bottom: $padding;
    gap: $padding;
  }
  @media #{breakpoints.$mat-lt-md} {
    app-associations-expansion-panel-wrapper .mat-panel-title-icon {
      display: none;
    }
  }
  flex-direction: column;
  align-items: flex-start;
  flex: 1;

  ::ng-deep mat-tab-body {
    margin: 0px $negativePadding;
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-tab-mdc-body-content {
      padding: 0px $padding;
    }
  }
  .tab-toolbar-wrapper.sticky ~ .tab-table .remberg-ngrx-table thead {
    top: var(--simpleHeaderHeight);
  }
  .remberg-ngrx-table:not(mat-expansion-panel *) thead {
    @include sticky-header;
    th {
      padding-bottom: 10px;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  ::ng-deep .mat-tab-mdc-label-active {
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-tab-mdc-label-content {
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        right: 0;
        bottom: 0;
        background-color: var(--primaryThemeColor);
      }
    }
  }
  mat-ink-bar {
    display: none !important;
  }
  .tab-toolbar-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $padding;
    &.sticky {
      @include sticky-header;
    }
    .remberg-toolbar,
    .toolbar-wrapper {
      flex: 1;
      display: flex;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
    }
    .toolbar-wrapper {
      padding: 10px 0px 14px 0px;
    }
  }
}

.stacked-panels :not(.mat-expansion-panel *):first-child {
  border-top: 0 !important;
}

.sidebar-headline {
  margin: 0px 0px;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-24px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.add-button-wrapper {
  padding: 10px 0px 14px 0px;
}
