.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: var(--textColorOnPrimaryBackground);
  .mdc-checkbox__mixedmark {
    --mdc-checkbox-selected-checkmark-color: var(--textColorOnPrimaryBackground);
  }
}

.mat-mdc-checkbox.mat-accent,
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-selected-checkmark-color: var(--white);
  .mdc-checkbox__mixedmark {
    --mdc-checkbox-selected-checkmark-color: var(--white);
  }
}

.mat-mdc-checkbox.checkbox-skip-ripple-size {
  .mdc-checkbox {
    // Counteract the size of the checkbox's padding so that it only takes up as much space as the checkmark
    // Below is the value applied by angular material inverted with -1
    margin: calc((var(--mdc-checkbox-state-layer-size) - 18px) / 2 * -1);
  }
}

.mat-mdc-checkbox label {
  padding-left: 0px !important;
}
