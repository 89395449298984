@use 'theme/breakpoints' as breakpoints;

.remberg-overview {
  .toolbar {
    width: 100%;
    max-width: 600px;
  }

  .header-sub-row-wrapper {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;

    &-first {
      margin-bottom: 15px;
      height: 48px;
    }

    &-second {
      gap: 20px;

      @media #{breakpoints.$mat-lt-sm} {
        gap: 10px;
      }
    }

    @media #{breakpoints.$mat-lt-sm} {
      align-items: center;

      &-only {
        flex-direction: column;
        gap: 15px;
      }
    }
  }
}

/* Page Setup*/
.mat-drawer-content {
  background-color: var(--grey25);
  color: var(--black);
}

div#main-container {
  margin-top: -40px;
  margin-left: -20px;
  min-width: 0;
}

.outer-container {
  margin-bottom: 20px;
  width: 100%;
  align-items: initial;
}

.outer-container > .header-button {
  min-width: fit-content !important;
  margin: auto auto auto 0;
  overflow: hidden;
}

.outer-container > h1.mat-headline-5,
.outer-container > div > h1.mat-headline,
.page-title {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  font-weight: var(--font-weight-regular);
}

.outer-container-toolbar {
  // 142px height
  min-height: var(--toolbarHeaderHeight);
  height: var(--toolbarHeaderHeight);
  max-height: var(--toolbarHeaderHeight);
  background: var(--backgroundColor);
  margin-bottom: 0px;
  padding-bottom: 20px;
  align-items: center;
  box-sizing: border-box;
  margin-top: -20px;
  margin-right: -20px;
  margin-left: -20px;
  padding: 20px;
  width: calc(100% + 40px);

  &.mono-layer {
    height: var(--toolbarHeaderHeightMonoLayer);
    min-height: var(--toolbarHeaderHeightMonoLayer);
    max-height: var(--toolbarHeaderHeightMonoLayer);
  }
}

.outer-container-simple {
  // 92px height
  min-height: var(--simpleHeaderHeight);
  height: var(--simpleHeaderHeight);
  max-height: var(--simpleHeaderHeight);
  background: var(--backgroundColor);
  margin-bottom: 0px;
  padding-bottom: 20px;
  margin-top: -20px;
  margin-right: -20px;
  margin-left: -20px;
  padding: 20px;
  width: calc(100% + 40px);
  align-items: center;
  display: flex;
  box-sizing: border-box;
  flex-direction: row !important;
}

.outer-container-simple-border {
  background-color: var(--white);
  border-bottom: 1px solid var(--borderColor);
}

.header-row {
  display: flex;
  width: 100%;
  align-items: center;
}

.outer-container-simple.no-background {
  background: none;
}

.outer-container-simple .header-button,
.outer-container-simple .page-title,
.outer-container-simple .mat-headline-5 {
  margin-bottom: 0px;
  min-width: initial !important;
}

.outer-container ::ng-deep .mat-mdc-raised-button.mat-primary.header-button,
.outer-container ::ng-deep .mat-mdc-raised-button.mat-primary.dropdown-arrow-button {
  height: 42px;
}

/* Visualization Type Select */
.visualization-type-select {
  margin-top: 32px;
}

/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
.visualization-type-select .mat-mdc-option .mat-option-text {
  display: flex;
  align-items: center;
}

/* Filter Search*/
.filter-search-small-view-wrapper {
  margin-bottom: 20px;
  height: 50px;
}

.filter-search-small-view-wrapper-large-search {
  height: 70px;
}

.value-addition {
  max-height: 21px;
}
