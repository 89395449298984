.mat-mdc-menu-item .mat-icon-no-color,
.mat-mdc-menu-item-submenu-trigger::after,
.mat-mdc-menu-item .mat-icon {
  color: var(--iconColor);
}

.mat-mdc-menu-panel.menu-no-max-width {
  max-width: 90vw;
}

.mat-mdc-menu-panel.menu-no-min-height {
  min-height: 0;
}

.mat-mdc-menu-panel.color-swatch-picker-height {
  min-height: 24px;
}

.mat-mdc-menu-item[disabled] {
  color: var(--disabledText);
}

.mat-mdc-menu-item .mat-icon-no-color,
.mat-mdc-menu-item-submenu-trigger::after,
.mat-mdc-menu-item .mat-icon {
  min-width: 24px;
}

.mat-mdc-menu-item-text {
  width: 100%;
}

.active-menu-item-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  .active-menu-item {
    font-weight: var(--font-weight-bold);
  }

  mat-icon.done-icon,
  mat-icon.checked-icon {
    margin-right: 0px;
  }
}
