/* Tabs */
.mat-mdc-tab-header {
  border-bottom: 1px solid var(--borderColor);
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  mat-icon {
    color: inherit;
  }
}

/* Add these classes to mat-tab-group */
.tab-group-with-top-margin {
  margin-top: 10px;
}

.tab-group-with-body-padding .mat-mdc-tab-body-wrapper {
  padding-top: 20px !important;
}

.tab-group-with-body-padding-ionic .mat-mdc-tab-body-wrapper {
  padding: 20px !important;
}

.mat-mdc-tab-group.mat-primary .mat-ink-bar,
.mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: var(--primaryThemeColor);
}
