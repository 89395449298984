/* Buttons */
@import './icons.scss';

.mat-mdc-unelevated-button > .mat-icon,
.mat-mdc-raised-button > .mat-icon,
.mat-mdc-outlined-button > .mat-icon {
  min-width: 1.125rem;
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 40px;
}

.mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: var(--black) !important;
}

.mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: var(--white) !important;
  --mdc-filled-button-label-text-color: var(--black) !important;
}

.mat-mdc-icon-button {
  --mdc-icon-button-disabled-icon-color: var(--disabledText) !important;

  &.mat-mdc-button-base {
    padding: 8px;
    --mdc-icon-button-state-layer-size: 40px;
  }

  .mat-mdc-button-touch-target {
    width: 40px !important;
    height: 40px !important;
  }

  &.inline-button {
    &.mat-mdc-button-base {
      padding: 3px;
      --mdc-icon-button-state-layer-size: 24px;
    }

    .mat-mdc-button-touch-target {
      width: 24px !important;
      height: 24px !important;
    }
  }
}

button:focus {
  outline: none !important;
}

// Use the color set by parent and do not differentiate between icon and text
.mat-mdc-button > *,
.mat-mdc-unelevated-button > *,
.mat-mdc-outlined-button > *,
.mat-mdc-raised-button > *,
.mat-mdc-icon-button > *,
.mat-mdc-fab > *,
.mat-mdc-mini-fab > * {
  color: inherit;
}

/* Primary */
.mat-mdc-raised-button.mat-primary:not(:disabled),
.mat-mdc-unelevated-button.mat-primary:not(:disabled) {
  color: var(--textColorOnPrimaryBackground);
}

.mat-mdc-raised-button.mat-accent:not(:disabled),
.mat-mdc-unelevated-button.mat-accent:not(:disabled) {
  color: var(--white);
}

.mat-mdc-raised-button:not(:disabled).mat-warn,
.mat-mdc-unelevated-button:not(:disabled).mat-warn {
  color: var(--white);
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-mdc-icon-button.mat-button-disabled.mat-button-disabled > .mat-icon {
  color: var(--disabledText);
}

.mat-mdc-icon-button {
  color: var(--iconColor);
}

.mat-mdc-raised-button.mat-primary.header-button-phone,
.button-dropdown.header-button-phone {
  width: calc(100% - 40px);
  position: fixed;
  left: 20px;
  // For Ionic and Web Cases
  bottom: max(env(safe-area-inset-bottom), 20px);
  z-index: 9999;
  box-shadow:
    0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
  height: 50px !important;
}

.button-dropdown.header-button-phone,
.mat-mdc-raised-button.mat-primary.header-button-detail-page-sidenav,
.header-button-detail-page-sidenav {
  width: calc(100% - 100px);
  right: 80px;
  --mdc-protected-button-disabled-container-color: var(--grey200);

  app-button-loader {
    width: 100%;
    height: 100%;
    button {
      width: 100%;
      height: 100%;
    }
    button.dropdown-arrow-button {
      max-width: 60px;
      width: 60px;
      min-width: 60px;
    }
  }
}

.mat-mdc-fab.mat-primary,
.mat-mdc-unelevated-button.mat-primary,
.mat-mdc-mini-fab.mat-primary,
.mat-mdc-raised-button.mat-primary {
  background-color: var(--primaryThemeColor);
  box-shadow: none;
}

.mat-mdc-fab.mat-primary,
.mat-mdc-unelevated-button.mat-primary,
.mat-mdc-mini-fab.mat-primary,
.mat-mdc-raised-button.mat-primary {
  color: var(--textColorOnPrimaryBackground);
}

.form-button-group,
.mat-mdc-card-content ::ng-deep .form-button-group {
  margin-top: 10px !important;
}

.form-button-group > button {
  margin-right: 20px !important;
}

.form-button-group > button:last-child {
  margin-right: 0px !important;
}

.form-button-group button {
  margin-bottom: 10px;
}

.form-button-group {
  margin-bottom: -10px;
}

.button-text-ellipsis {
  overflow: hidden !important;
  max-width: 100% !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.header-button ::ng-deep span {
  height: 32px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.header-button-responsive {
  margin-left: 0px !important;
  margin-bottom: 10px !important;
}

.mat-mdc-raised-button.mat-primary.add-button-small-view {
  margin-bottom: 20px;
}

.new-task-button {
  margin: auto 0 auto auto !important;
}

.mat-mdc-raised-button {
  box-shadow: none !important;
  border: 1px solid var(--borderColor) !important;
}

.primary-button-icon {
  margin-right: 5px;
  color: var(--textColorOnPrimaryBackground);
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-mdc-button.mat-primary .mat-button-focus-overlay,
.mat-mdc-icon-button.mat-primary .mat-button-focus-overlay,
.mat-mdc-outlined-button.mat-primary .mat-button-focus-overlay {
  color: var(--primaryThemeColor);
}

.mat-mdc-outlined-button.mat-warn {
  border-top-color: var(--warnColor);
  border-bottom-color: var(--warnColor);
  border-left-color: var(--warnColor);
  border-right-color: var(--warnColor);
}

.mat-mdc-raised-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button {
  min-width: fit-content;
}

.mat-mdc-unelevated-button.mat-primary.mat-button-disabled,
.mat-unelevated-button.mat-accent.mat-button-disabled,
.mat-unelevated-button.mat-warn.mat-button-disabled,
.mat-unelevated-button.mat-button-disabled.mat-button-disabled,
.mat-mdc-raised-button.mat-primary.mat-button-disabled,
.mat-mdc-raised-button.mat-accent.mat-button-disabled,
.mat-raised-button.mat-warn.mat-button-disabled,
.mat-raised-button.mat-button-disabled.mat-button-disabled,
.mat-mdc-fab.mat-primary.mat-button-disabled,
.mat-mdc-fab.mat-accent.mat-button-disabled,
.mat-fab.mat-warn.mat-button-disabled,
.mat-fab.mat-button-disabled.mat-button-disabled,
.mat-mdc-mini-fab.mat-primary.mat-button-disabled,
.mat-mdc-mini-fab.mat-accent.mat-button-disabled,
.mat-mini-fab.mat-warn.mat-button-disabled,
.mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: var(--disabledButtonBackground);
}

.mat-mdc-outlined-button {
  &.ai-button:not(:disabled) {
    color: var(--black) !important;
    border-color: var(--aiColor);

    mat-icon {
      color: var(--aiColor) !important;
    }
  }
}

.mat-mdc-raised-button.mat-primary {
  &.ai-button:not(:disabled) {
    background-color: var(--aiColor);
    color: var(--white);
    border-color: var(--aiColor);

    mat-icon {
      color: var(--white);
    }
  }

  &.ai-button:not(:disabled):hover {
    background-color: var(--aiFocusColor);
  }

  &.ai-button:not(:disabled):active,
  &.ai-button:not(:disabled):focus {
    background-color: var(--aiFocusColor);
    border-color: var(--aiColor);
  }
}
.mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: var(--black) !important;
}

/*
  Wojciech: This is done so that the button label does not appear on top of non root mat-sidenavs
  nested in html siblings defined later than parents of a button.
  Angular Material documentation motivates z-index: 1 as accessibility improvement, this can be traded off for now
  to not have to handle each html tree separately.
*/
.mat-mdc-button .mdc-button__label,
.mat-mdc-unelevated-button .mdc-button__label,
.mat-mdc-raised-button .mdc-button__label,
.mat-mdc-outlined-button .mdc-button__label {
  z-index: 0 !important;
}

.mat-mdc-button .mdc-button__label,
.mat-mdc-unelevated-button .mdc-button__label,
.mat-mdc-raised-button .mdc-button__label,
.mat-mdc-outlined-button .mdc-button__label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.mat-mdc-outlined-button.squared-icon-button,
.mat-mdc-raised-button.squared-icon-button {
  padding: 0;
  min-width: 40px;
  width: 40px;
  height: 40px;

  .mat-icon {
    margin-right: 0px;
    margin-left: 0px;
    font-size: var(--mediumIcon);
    height: var(--mediumIcon);
    width: var(--mediumIcon);
    min-width: var(--mediumIcon);
    line-height: var(--mediumIcon);
  }
}

.ai-color-icon {
  --mat-menu-item-icon-color: var(--aiColor);
}
