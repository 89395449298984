@use 'theme/breakpoints' as breakpoints;
@use '@angular/material' as mat;

.remberg-toolbar {
  app-search-bar {
    background-color: var(--white);
  }

  .mat-mdc-unelevated-button > .mat-icon,
  .mat-mdc-raised-button > .mat-icon,
  .mat-mdc-outlined-button > .mat-icon,
  .mat-mdc-button > .mat-icon {
    min-width: var(--icon-24px);
    width: var(--icon-24px);
    height: var(--icon-24px);
    font-size: var(--icon-24px);
    color: var(--black);
    margin-right: 0px !important;
  }

  .mat-mdc-button > .mat-icon.info-icon {
    font-size: var(--smallIcon);
    height: var(--smallIcon);
    width: var(--smallIcon);
    min-width: var(--smallIcon);
    margin-left: 10px;
    margin-right: 10px;
    color: var(--grey700);
  }

  .more-actions-item > .mat-icon {
    margin-left: 0px !important;
  }

  .mat-mdc-button > .mat-icon.down-icon {
    margin-left: 10px;
    flex-shrink: 0;
  }

  .icon-aligner,
  .icon-aligner-responsive {
    margin-left: 5px;
  }

  .toolbar-wrapper {
    display: flex;
    justify-content: flex-end;
    gap: 20px;

    @media #{breakpoints.$mat-lt-sm} {
      justify-content: flex-start;
      gap: 10px;
    }
  }

  .toolbar-item {
    height: 48px;
    background-color: var(--white);
    max-width: 388px;

    @include mat.form-field-density(-2);

    app-search-bar {
      .mat-mdc-form-field-infix {
        padding-left: 4px;
      }
    }

    @media #{breakpoints.$mat-lt-sm} {
      &.mdc-button {
        min-width: 48px;

        .mat-icon {
          margin-left: 0px !important;
        }
      }

      padding: 0;
      .icon-aligner-responsive {
        display: none;
      }
    }
  }

  .toolbar-button.mat-mdc-raised-button,
  .toolbar-button.mat-mdc-button {
    padding: 0;
    margin: 6px;
    min-width: 38px;
    width: 38px;
    height: 38px;
  }
}

@media #{breakpoints.$mat-lt-lg} {
  .sidenav-content-pushed-by-sidenav {
    .overview-info {
      min-width: 170px;
    }

    .remberg-toolbar .toolbar-item,
    .overview-info .toolbar-item {
      // min-width: 48px;
      padding: 0;

      .icon-aligner,
      .trigger-text {
        display: none;
      }
    }
  }
}

.overview-sidenav {
  top: var(--mainNavigationToolbarHeight) !important;
}

.more-actions-item,
.more-actions-item-responsive {
  // min-width: 46px;
  padding: 0;
  &-responsive {
    @media #{breakpoints.$mat-lt-md} {
      display: none !important;
    }
  }
}
