@use 'theme/breakpoints' as breakpoints;
@use 'theme/fonts' as fonts;

/* Form General Styles */
.form-title-background ::ng-deep .mdc-text-field--outlined {
  background-color: var(--white);
}

h2.field-section-title {
  margin-top: 0px;
}

.display-headline {
  font-size: var(--font-size-16px);
  color: var(--black);
}

/* Form Card Styles */
/* Form Card */
.form-instance-renderer {
  h3,
  .mat-panel-title,
  mat-panel-title .mat-headline-6,
  .form-panel-title {
    @include fonts.mat-h3;
  }

  .mat-expansion-panel {
    padding-bottom: var(--material-input-padding-bottom);
  }

  .mat-expansion-panel-header.mat-expanded {
    height: 46px;
  }

  .mat-expansion-panel-header {
    height: 42px;
  }

  .panel-header-error {
    h3 {
      color: var(--warnColor) !important;
    }
    span:after {
      color: var(--warnColor) !important;
    }
  }

  .mat-expansion-panel:not([class*='mat-elevation-z']).form-panel,
  .mat-mdc-card:not([class*='mat-elevation-z']).form-panel {
    background: initial;
    box-shadow: inherit;
    border: initial !important;
    margin-left: -25px;
    margin-right: -25px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .form-panel
    ::ng-deep
    .mat-expansion-panel:not(.mat-expanded)
    .mat-expansion-panel-header:not([aria-disabled='true']):hover {
    background: initial;
  }
}

/* Form Card Responsive */
@media #{breakpoints.$mat-lt-sm} {
  .form-panel ::ng-deep .mat-expansion-panel-body,
  .form-panel ::ng-deep .mat-expansion-panel-header {
    margin-left: 0px;
    margin-right: 0px;
  }

  .form-panel ::ng-deep .mat-expansion-panel-body {
    padding-left: 0px;
    padding-right: 0px;
  }

  .form-panel ::ng-deep .mat-expansion-panel-header,
  .form-panel ::ng-deep .mat-expansion-panel-body {
    padding-left: 2px;
    padding-right: 2px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .form-panel ::ng-deep .mat-expansion-panel-body,
  .form-panel ::ng-deep .mat-expansion-panel-header {
    margin-left: 0px;
    margin-right: 0px;
  }
}

/* Form Card Header */
// ToDo:
// https://remberg.atlassian.net/browse/DES-375?atlOrigin=eyJpIjoiOWI0MDI5MTQyNDQyNDg1Nzk0ZjEyZTNmZTYwZTEyZWQiLCJwIjoiaiJ9
h3.form-panel-title-header {
  margin: 0px;
  font-size: var(--font-size-16px);
}

.form-panel-description {
  justify-content: flex-end;
  align-items: center;
}

/* Overwrite my-mat-form-field*/
.my-mat-form-field {
  margin: 5px 0px;
}

/* Form Card Content*/
.has-elements {
  margin-top: 20px;
}

/* Field Error */
.field-error-wrapper {
  gap: 5px;
  margin-top: 20px;
  color: var(--warnColor);
  font-size: var(--font-size-Dot75rem);
  line-height: var(--line-height-1rem);
}
.mat-icon.field-error-icon {
  font-size: var(--xSmallIcon);
  height: var(--xSmallIcon);
  width: var(--xSmallIcon);
  min-width: var(--xSmallIcon);
  color: var(--warnColor);
}

/* Cards View */
.top-row-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
}

.form-preview-card {
  padding: 20px;
  background: var(--white);
  position: relative;
}

.mat-mdc-icon-button.menu-action-icon {
  position: absolute;
  right: 10px;
  top: 20px;
}

.card-title {
  font-size: var(--font-size-16px);
  font-weight: var(--font-weight-bold);
  overflow-wrap: anywhere;
}

.card-sub-content {
  width: 100%;
}

.additional-info-wrapper {
  margin-bottom: -10px;
}

.state-wrapper {
  background-color: var(--grey200);
  color: var(--black);
  padding: 0px 10px;
  max-width: 250px;
  width: fit-content;
  border-radius: var(--borderRadius);
}

.state-wrapper > mat-icon {
  font-size: var(--mediumIcon);
  height: var(--mediumIcon);
  width: var(--mediumIcon);
  min-width: var(--mediumIcon);
  margin-right: 5px;
}

.state-wrapper-icon {
  display: flex;
}

.state-wrapper {
  margin-bottom: 10px;
}

.status-icon {
  margin-right: 5px;
}

.warranty-wrapper {
  background-color: var(--statusGreen);
  color: var(--statusGreenText);
  display: flex;
  align-items: center;
}

.card.form-preview-card.card-editable:hover {
  cursor: pointer;
  background-color: var(--cardHoverColor);
}

.description-wrapper {
  margin: 5px 0px;
  overflow-wrap: break-word;
}

.state-icon {
  margin-top: 3px;
}

.preview-indicator-asset,
.preview-indicator-company,
.preview-indicator-organization,
.preview-indicator-user,
.preview-indicator-contact {
  height: 20px;
}

.forms-locked-value {
  font-size: var(--font-size-14px);
  line-height: var(--font-size-20px);
}

/* Hide underline for mat-form-fields */
.mat-form-field-no-underline {
  .mdc-line-ripple {
    display: none;
  }
}

.custom-help-text,
.html-content {
  padding-bottom: 12px;
  padding-top: 12px;
  font-size: var(--font-size-14px);
  line-height: var(--line-height-18px);
  color: var(--grey900);
  p {
    line-height: var(--line-height-18px);
    margin: 8px 0px;
  }
  h1 {
    font-size: var(--font-size-20px);
    line-height: var(--line-height-26px);
    margin: 0px;
    margin-bottom: 18px;
  }
  h2 {
    font-size: var(--font-size-18px);
    line-height: var(--line-height-22px);
    margin: 0px;
    margin-bottom: 14px;
  }
  h3 {
    font-size: var(--font-size-16px);
    margin: 0px;
    margin-bottom: 10px;
  }
}

.help-text-before {
  padding: 0px;
  margin-bottom: 24px;
}

.help-text-after {
  padding: 0px;
  margin-top: 24px;
}

.mat-mdc-list-base.flex-gap-col-20 {
  padding-top: 0px;
}

.password-form-field {
  .mat-mdc-form-field-subscript-wrapper {
    display: flex;
  }
  .mat-mdc-form-field-error-wrapper {
    position: inherit;
    margin-bottom: 10px;
  }
  .mat-mdc-form-field-error.mat-mdc-form-field-bottom-align::before {
    display: none;
  }
}

.checkbox-gap-10 {
  .mdc-form-field {
    gap: 10px;
  }
}
