/* Chips */
.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: var(--black);
  --mdc-chip-elevated-container-color: var(--grey50);
  --mdc-chip-elevated-disabled-container-color: var(--grey50);
  --mdc-chip-focus-state-layer-color: var(--black);
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: var(--black);
  --mdc-chip-with-icon-icon-color: var(--black);
  --mdc-chip-with-icon-disabled-icon-color: var(--black);
  --mdc-chip-with-icon-selected-icon-color: var(--black);
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: var(--black);
  --mdc-chip-with-trailing-icon-trailing-icon-color: var(--black);
  --mdc-chip-label-text-weight: var(--font-weight-regular);
  // --mdc-chip-label-text-font: var(--roboto);
  // --mdc-chip-label-text-line-height: var(--line-height-20px);
  // --mdc-chip-label-text-size: var(--font-size-14px);
  // --mdc-chip-label-text-tracking: normal;
}

.mat-mdc-standard-chip {
  // border-radius: var(--mdc-chip-container-shape-radius);
  // height: var(--mdc-chip-container-height);
  // --mdc-chip-container-shape-family: rounded;
  // --mdc-chip-container-shape-radius: 16px 16px 16px 16px;
  --mdc-chip-with-avatar-avatar-shape-family: outline !important;
  // --mdc-chip-with-avatar-avatar-shape-radius: 14px 14px 14px 14px;
  // --mdc-chip-with-avatar-avatar-size: 28px;
  // --mdc-chip-with-icon-icon-size: 18px;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  border: 1px solid var(--borderColor);
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 28px;
}

// chip text should have max 100% width to prevent overflow
.mdc-evolution-chip-set__chips {
  max-width: 100% !important;
}

.mat-mdc-standard-chip .mdc-evolution-chip__cell--primary,
.mat-mdc-standard-chip .mdc-evolution-chip__action--primary,
.mat-mdc-standard-chip .mat-mdc-chip-action-label {
  overflow: hidden !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}
