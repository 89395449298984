// Scheduler
@import '@bryntum/scheduler/scheduler.material.css';
.scheduler-v2 {
  .b-widget {
    --color-indigo: var(--paletteBrightBlue);
    --color-blue: var(--paletteBlue);
    --color-cyan: var(--paletteCyan);
    --color-red: var(--paletteRed);
    --color-deep-orange: var(--paletteOrange);
    --color-orange: var(--paletteBrightOrange);
    --color-amber: var(--paletteBrightYellow);
    --color-yellow: var(--paletteYellow);
    --color-green: var(--statusGreen);
    --color-teal: var(--paletteBrightGreen);
    --color-light-green: var(--paletteBrightGreen);
    --color-lime: var(--paletteBrightYellow);
    --color-purple: var(--palettePurple);
    --color-pink: var(--palettePink);
    --color-violet: var(-paletteViolet);
    --color-gray: var(--grey300);
    --color-light-gray: var(--grey100);
    --color-dark-gray: var(--grey700);
    --color-white: var(--white);
    --color-black: var(--black);
    overflow: hidden;
    line-height: initial;
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-14px);
    box-sizing: border-box;
    color-scheme: light;
  }

  .b-timelinebase:not(.b-sch-vertical) .b-grid-header-container {
    border-bottom: 1px solid var(--borderColor);
    background: var(--white);
  }

  .b-grid-header-container .b-sch-timeaxiscolumn {
    background: var(--white);
  }

  .b-panel {
    color: var(--black);
  }

  .b-grid-cell {
    color: var(--black);
  }

  .b-grid-header,
  .b-grid-header-text {
    padding: 0;
  }

  .b-sch-event {
    --event-color: var(--black);
    --event-hover-color: var(--black);
    --event-background-image: none;
  }

  .b-sch-event-wrap {
    min-width: 12px;
  }

  .b-sch-event-content,
  .b-sch-event-content > * {
    width: 100%;
  }

  .b-sch-event:not(.b-milestone) .b-sch-event-content {
    margin: 0;
    left: 0;
    right: 0;
  }

  .b-sch-style-none .b-sch-event:not(.b-milestone) {
    --event-background-image: none;
    width: 100%;
  }

  // custom css class for the resource cell
  .b-grid-row.b-selected,
  .b-gridbase:not(.b-moving-splitter)
    .b-grid-subgrid:not(.b-hide-row-hover)
    .b-grid-row:not(.b-group-row).b-hover,
  .b-gridbase:not(.b-moving-splitter)
    .b-grid-subgrid:not(.b-hide-row-hover)
    .b-grid-row:not(.b-group-row).b-hover
    .b-grid-cell {
    background: transparent;
  }

  .b-sch-event-wrap .transparent {
    opacity: 0.2;
  }

  .b-sch-event-wrap {
    opacity: 1;
  }

  .b-resource-cell {
    padding: 0;

    &.b-row-reorder-grip {
      flex-direction: row-reverse;
      cursor: grab;

      &::before {
        cursor: grab;
        display: none;
      }

      &:hover::before {
        display: flex;
      }
    }
  }

  .b-row-reordering .b-resource-cell {
    cursor: grabbing;

    &.b-row-reorder-grip:not(.b-selected)::before {
      visibility: hidden;
    }
  }

  .b-grid-row.b-drag-proxy {
    background: var(--white);
    border: 1px solid var(--borderColor);
    opacity: 1;
    z-index: 5000;

    .b-resource-cell.b-row-reorder-grip::before {
      visibility: visible;
    }
  }

  /*#region Current Time */
  .b-grid-header .b-sch-timerange.b-sch-current-time {
    background-color: var(--primaryThemeColor);
    color: var(--textColorOnPrimaryBackground);
  }

  .b-grid-header .b-sch-timerange.b-timerange-TimeRanges.b-sch-line.b-sch-current-time {
    display: none;
  }

  [data-preset-id='hourAndDayPreset']
    .b-grid-header
    .b-sch-timerange.b-timerange-TimeRanges.b-sch-line.b-sch-current-time {
    display: inherit;
  }

  .b-timeline-subgrid .b-sch-current-time {
    border-left-color: var(--primaryThemeColor);
  }

  .b-timeline-subgrid div.b-sch-current-time {
    border-inline-start-color: var(--primaryThemeColor);
  }

  /*#endregion Current Time */

  /*#region Time Ranges */
  .b-grid-header .b-sch-timerange:not(.b-sch-current-time) {
    background-color: transparent !important;
  }
  /*#endregion Time Ranges */

  .unassigned-scheduler {
    .b-grid-header-container {
      display: none;
    }
    .b-resource-cell {
      align-items: flex-start;
    }
  }

  .b-column-line,
  .b-grid-row {
    border-color: var(--borderColor);
  }

  .b-virtual-scrollers .b-grid-splitter {
    visibility: hidden;
  }

  .b-sch-style-none {
    margin-left: 0;
  }

  .b-gantt-task:hover,
  .b-sch-style-none .b-sch-event:hover,
  .b-gantt-task.b-task-selected,
  .b-gantt-task.b-sch-event-selected,
  .b-sch-style-none .b-sch-event.b-task-selected,
  .b-sch-style-none .b-sch-event.b-sch-event-selected {
    --event-background-image: none;
    cursor: default;
  }

  .b-sch-event.b-sch-event-selected,
  .b-sch-event.b-sch-event-selected * {
    font-weight: inherit;
  }

  .b-splitter.b-horizontal {
    background: var(--borderColor);
  }

  .b-splitter.b-horizontal:hover {
    height: 4px !important;
    max-height: 4px !important;
  }

  .b-grid-splitter {
    background-color: var(--white);
  }

  .b-gridbase.b-split .b-grid-splitter:not(.b-disabled) .b-grid-splitter-inner,
  .b-grid-splitter.b-hover .b-grid-splitter-inner {
    width: 2px;
    background-color: var(--borderColor);
    background: var(--borderColor);
    max-width: 2px;
    left: 0;
    transition: none;
  }

  .b-grid-splitter.b-hover .b-grid-splitter-inner,
  .b-grid-splitter.b-partner-splitter-hover .b-grid-splitter-inner {
    width: 4px !important;
    max-width: 4px !important;
  }

  .b-gridbase.b-split .b-grid-splitter:not(.b-disabled) {
    background-color: var(--borderColor);
    background: var(--borderColor);
    flex: none;
  }

  .b-gridbase.b-split .b-grid-splitter:not(.b-disabled) .b-grid-splitter-buttons {
    visibility: hidden;
  }

  .b-grid-header-container .b-header {
    box-shadow: none;
  }

  .b-gridbase.b-split:not(.b-grid-splitter-no-drag)
    .b-grid-splitter:not(.b-disabled, .b-grid-splitter-collapsed)
    .b-grid-splitter-inner {
    cursor: ew-resize;
  }

  .b-splitter:hover::after,
  .b-splitter.b-hover::after,
  .b-splitter.b-moving::after {
    display: none;
  }

  .b-gridbase:not(.b-moving-splitter)
    .b-grid-subgrid:not(.b-hide-row-hover)
    .b-grid-row:not(.b-group-row).b-hover,
  .b-gridbase:not(.b-moving-splitter)
    .b-grid-subgrid:not(.b-hide-row-hover)
    .b-grid-row:not(.b-group-row).b-hover
    .b-grid-cell,
  .b-gridbase:not(.b-moving-splitter)
    .b-grid-subgrid:not(.b-hide-row-hover)
    .b-grid-row:not(.b-group-row).b-hover
    .b-grid-cell,
  .b-grid-header-container .b-sch-timeaxiscolumn .b-sch-header-timeaxis-cell:hover {
    background-color: var(--cardHoverColor);
  }

  .b-grid-row.b-selected,
  .b-gridbase:not(.b-moving-splitter)
    .b-grid-subgrid:not(.b-hide-row-hover)
    .b-grid-row:not(.b-group-row).b-hover.b-selected {
    background-color: var(--grey75);
  }

  .b-grid-header.b-sch-timeaxiscolumn-levels-1 .b-sch-timerange {
    height: 20px;
  }

  .b-row-reordering .b-row-drop-indicator,
  .b-row-reordering .b-row-drop-indicator.b-drag-invalid {
    background-color: var(--primaryThemeColor);
  }

  // Dependencies styles
  .b-sch-dependency {
    stroke-width: 1;
    stroke: var(--primaryThemeColor);
  }

  .b-sch-dependency-arrow {
    fill: var(--primaryThemeColor);
  }
  .b-eventresize:not(.b-readonly):not(.b-creating-dependency)
    .b-sch-event-wrap.b-sch-event-hover:not(.b-sch-style-line)
    > .b-sch-event:not(.b-segmented).b-sch-event-resizable-start:before,
  .b-eventresize:not(.b-readonly):not(.b-creating-dependency)
    .b-sch-event-wrap.b-sch-event-hover:not(.b-sch-style-line)
    > .b-sch-event:not(.b-segmented).b-sch-event-resizable-end:after,
  .b-eventresize:not(.b-readonly):not(.b-creating-dependency)
    .b-sch-event-wrap.b-sch-event-hover:not(.b-sch-style-line)
    > .b-sch-event:not(.b-segmented).b-sch-event-resizable-true:before,
  .b-eventresize:not(.b-readonly):not(.b-creating-dependency)
    .b-sch-event-wrap.b-sch-event-hover:not(.b-sch-style-line)
    > .b-sch-event:not(.b-segmented).b-sch-event-resizable-true:after,
  .b-eventresize:not(.b-readonly):not(.b-creating-dependency)
    .b-sch-event-wrap:not(.b-sch-style-line)
    .b-sch-event-resizing.b-sch-event-resizable-start:before,
  .b-eventresize:not(.b-readonly):not(.b-creating-dependency)
    .b-sch-event-wrap:not(.b-sch-style-line)
    .b-sch-event-resizing.b-sch-event-resizable-end:after,
  .b-eventresize:not(.b-readonly):not(.b-creating-dependency)
    .b-sch-event-wrap:not(.b-sch-style-line)
    .b-sch-event-resizing.b-sch-event-resizable-true:before,
  .b-eventresize:not(.b-readonly):not(.b-creating-dependency)
    .b-sch-event-wrap:not(.b-sch-style-line)
    .b-sch-event-resizing.b-sch-event-resizable-true:after {
    opacity: 0;
    cursor: ew-resize !important;
    z-index: 99;
  }

  .b-sch-event-resizable-true::before,
  .b-sch-event-resizable-true::after,
  .b-sch-event-resizable-start::before,
  .b-sch-event-resizable-start::after,
  .b-sch-event-resizable-end::before,
  .b-sch-event-resizable-end::after {
    --handle-inset: 0;
  }
  // Sub Header class
  .b-sch-header-row-1 {
    display: none;
  }
}

.b-column-line-major {
  border-color: var(--grey400);
}

.b-tooltip {
  margin: 10px;

  .b-tooltip-content {
    line-height: initial;
    padding: 0;
    border: 0;
    padding-block: 0 !important;
    margin-block: 0 !important;
  }

  &.feedback-custom-tooltip {
    margin: 0 0 20px 0;
    box-shadow: none;

    .b-anchor {
      display: block;
      width: 5px;
      // Below is to compensate for border rounded in case of resize
      margin-top: -1px;
    }

    .b-tooltip-content {
      background-color: var(--grey600);
    }
  }

  .date-time-tooltip {
    display: inline-block;
    text-align: left;
    justify-content: left;
    font-size: var(--font-size-12px);
    padding: 10px 10px 0 10px;

    .date-time-info {
      margin-left: -10px;
      vertical-align: top;
    }

    .mat-icon {
      vertical-align: middle;
      font-size: var(--xSmallIcon);
      height: 24px;
      width: 24px;
      color: var(--grey300);
    }
  }
}

// Styles for the Bryntum Menu (need to be outside of the scheduler class)
.b-panel.b-floating {
  border-radius: 5px;
}

.b-menuitem {
  font-size: var(--font-size-14px);
  font-family: var(--roboto);

  .menu-item {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  span.b-menu-text {
    margin-inline: 0.6em !important;
  }
}

.b-splitter:hover::after,
.b-splitter.b-hover::after,
.b-splitter.b-moving::after {
  display: none;
}

// Needed to display correctly while dragging
.b-sch-event-content,
.b-sch-event-content > * {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100%;
}

.b-sch-event-wrap.b-milestone-wrap {
  min-width: 12px;
  content: none !important;
  background: transparent !important;

  .b-sch-event {
    width: 100% !important;

    .b-sch-event-content::before {
      display: none;
    }

    label {
      left: 0 !important;
      position: relative !important;
    }
  }
}

.b-float-root .b-sch-event-wrap .b-sch-event {
  grid-area: auto;
}
