.remberg-ui {
  section {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
  }
  .remberg-ui-info-text {
    padding: 12px;
    border: 1px solid var(--borderColor);
    border-radius: var(--borderRadius);
    background: var(--internalNoteColor);
    font-style: italic;
    display: flex;
    margin: 0;
    margin-bottom: 20px;
    gap: 5px;
  }
}
