.search-bar-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
  height: 32px;
}

.search-bar-wrapper > mat-icon {
  width: var(--icon-24px);
  height: var(--icon-24px);
  font-size: var(--icon-24px);
}

.search-bar-wrapper > mat-icon:hover {
  cursor: pointer;
}

.search-bar-wrapper > mat-form-field {
  transition: max-width 0.8s cubic-bezier(0, 0.795, 0, 1);
  overflow: hidden;
  margin-left: 10px;
}

.search-bar-wrapper > mat-form-field.show-form-field {
  max-width: 200px;
}

.search-bar-wrapper > mat-form-field.show-form-field-isSmallView {
  max-width: 150px;
}

.close-icon:hover {
  cursor: pointer;
}

input.placeholder-truncate-text:placeholder-shown {
  text-overflow: ellipsis;
}
