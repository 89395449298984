.mde-popover-above .mde-popover-direction-arrow {
  border-color: transparent transparent rgba(0, 0, 0, 0.07) !important;
}

.mde-popover-above .mde-popover-direction-arrow:after {
  content: '';
  position: absolute;
  top: 1px;
  left: -8px;
  border-width: 0px 8px 8px;
  border-style: solid;
  border-color: transparent transparent var(--white);
}

.mde-popover-below .mde-popover-direction-arrow:before {
  content: '';
  position: absolute;
  top: -9px;
  left: -8px;
  border-width: 8px 8px 0px;
  border-style: solid;
  border-color: var(--white) transparent transparent;
}

.mde-popover-below.note-style .mde-popover-direction-arrow:before {
  border-color: var(--internalNoteColor) transparent transparent;
}
.mde-popover-above.note-style .mde-popover-direction-arrow:after {
  border-color: transparent transparent var(--internalNoteColor);
}

.mde-popover-no-arrow .mde-popover-direction-arrow {
  display: none;
}

.mde-popover-content .mat-mdc-card {
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 20%);
  border: 1px solid var(--borderColor);
}
