// Needed to support the status bar on Android devices
:root {
  --android-top-shift: 20px;
}

.plt-android .interface-padded {
  height: calc(100% - 2 * var(--interfacePadding));
  app-forms {
    height: 100%;
  }
  .bottom-padding-mobile-ionic {
    padding-bottom: 64px !important;
  }
}

.plt-android {
  .mat-mdc-paginator-page-size {
    display: none;
  }
}
