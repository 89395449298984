/* stylelint-disable */

/* Color Variables */
:root {
  /* Status color palette */
  --statusRed: var(--paletteBrightRed);
  --statusRedBorder: var(--paletteBrightRedDark200);
  --statusRedDisabled: var(--paletteRedLight600);
  --statusRedText: var(--paletteWhite);
  --statusRedTextAlternative: var(--paletteBlackLight900);

  --statusGreen: var(--paletteBrightGreen);
  --statusGreenBorder: var(--paletteBrightGreenDark200);
  --statusGreenDisabled: var(--paletteBrightGreenDark400);
  --statusGreenText: var(--paletteWhite);
  --statusGreenTextAlternative: var(--paletteBlackLight900);

  --statusOrange: var(--paletteBrightOrange);
  --statusOrangeBorder: var(--paletteBrightOrangeDark200);
  --statusOrangeDisabled: var(--paletteBrightOrangeDark400);
  --statusOrangeText: var(--paletteBlack);
  --statusOrangeTextAlternative: var(--paletteBlackLight100);

  --statusYellow: var(--paletteYellow);
  --statusYellowBorder: var(--paletteYellowDark200);
  --statusYellowDisabled: var(--paletteYellowDark400);
  --statusYellowText: var(--paletteBlack);
  --statusYellowTextAlternative: var(--paletteBlackLight100);

  --statusBlue: var(--paletteBrightBlue);
  --statusBlueBorder: var(--paletteBrightBlueDark200);
  --statusBlueDisabled: var(--paletteBrightBlueDark400);
  --statusBlueText: var(--paletteWhite);
  --statusBlueTextAlternative: var(--paletteBlackLight900);

  --statusGrey: var(--paletteBlackLight800);
  --statusGreyBorder: var(--paletteBlackLight600);
  --statusGreyDisabled: var(--paletteBlackLight400);
  --statusGreyText: var(--paletteBlack);
  --statusGreyTextAlternative: var(--paletteBlackLight100);

  --statusWarn: var(--paletteWarn);
  --statusWarnBorder: var(--paletteWarnDark200);
  --statusWarnDisabled: var(--paletteWarnLight800);
  --statusWarnText: var(--paletteWhite);
  --statusWarnTextAlternative: var(--paletteBlackLight900);

  /* Grey palette */
  --white: var(--paletteWhite);
  --black: var(--paletteBlack);
  --blackDark: var(--paletteBlackDark1000);
  --blackLight: var(--paletteBlackLight400);

  --grey900: var(--paletteBlackLight100);
  --grey800: var(--paletteBlackLight200);
  --grey700: var(--paletteBlackLight300);
  --grey600: var(--paletteBlackLight400);
  --grey500: var(--paletteBlackLight500);
  --grey400: var(--paletteBlackLight600);
  --grey300: var(--paletteBlackLight700);
  --grey200: var(--paletteBlackLight800);
  --grey100: var(--paletteBlackLight900);
  --grey75: var(--paletteBlackLight925);
  --grey50: var(--paletteBlackLight950);
  --grey25: var(--paletteBlackLight975);
  --grey20: var(--paletteBlackLight980);
  --grey15: var(--paletteBlackLight985);

  /* Theme colors */
  --primaryThemeColor: var(--palettePrimary);
  --primaryThemeColorDark: var(--palettePrimaryDark200);
  --primaryThemeColorLight: var(--palettePrimaryLight400);

  --backgroundThemeColor: var(--paletteBlackLight975);
  --backgroundThemeColorDark: var(--paletteBlackLight800);
  --backgroundThemeColorLight: var(--paletteBlackLight985);

  --warnColor: var(--paletteWarn);
  --warnColorDark: var(--paletteWarnDark200);
  --warnColorLight: var(--paletteWarnLight400);

  // This gets either black or white for text depending on primaryOrbackgroundThemeColor (e.g. text on buttons that switch colors)
  --textColorOnPrimaryBackground: var(--black);

  /* App colors */
  --backgroundColor: var(--grey25);
  --prefillColor: var(--paletteBlueLight900);
  --aiColor: var(--palettePurple);
  --aiBackgroundColor: var(--palettePurpleLight900);
  --aiFocusColor: var(--palettePurpleLight200);

  // Borders
  --borderColor: rgba(0, 0, 0, 0.12); //should be opaque
  --borderColorDisabled: rgba(0, 0, 0, 0.06); //should be opaque

  // Disabled
  --disabled: var(--grey25);
  --disabledButtonBackground: var(--grey100);
  --disabledButtonText: var(--grey200);
  --disabledText: var(--grey600);

  // Cards
  --chatBackgroundColor: var(--white);
  --internalNoteColor: var(--palettePrimaryLight900);
  --publicNoteColor: var(--white);
  --portalNoteColor: var(--paletteBlackLight950);
  --cardHoverColor: var(--grey50);
  --cardFocusColor: var(--grey25);
  --cardSelectedColor: var(--grey100);

  /* Icon color */
  --iconColor: var(--grey700);
  --disabledInputIconColor: var(--grey500);

  /* Text color */
  --textColor: var(--black);
  --textColorMuted: var(--grey600);
  --textColorInactive: var(--grey300);
  --textColorLight: rgba(0, 0, 0, 0.54); //should be opaque
  --textColorDark: rgba(0, 0, 0, 0.95); //should be opaque
  --linkColor: var(--paletteBrightBlue);
  --validInputLabel: rgba(0, 0, 0, 0.6); //should be opaque
}

/* Border Variables */
:root {
  --dialogTopPadding: 24px;
  --cardPadding: 24px;
  --dialogPadding: 24px;
  --borderRadius: 4px;
}

/* Height Variables */
:root {
  // Defining sizes for gt-sm (medium to large views)
  --mainNavigationToolbarHeight: 64px;
  --footerHeight: 64px;
  --simpleHeaderHeight: 82px;
  --simpleHeaderWithPromptHeight: 102px;
  --toolbarHeaderHeight: 152px;
  --toolbarHeaderHeightMonoLayer: 86px;
  --interfacePadding: 20px;
  --negativeInterfacePadding: -20px;

  // rb-dialog heights
  --dialogHeaderHeight: 71px;
  --dialogHeaderHeightSm: 89px;
  --dialogHeaderHeightMd: 81px;
  --dialogFooterHeight: 81px;

  // sidebars
  --legacyLayoutSidebarWidth: 380px;

  // Lists
  --listRowHeight: 64px;

  // Scheduling
  --cardHeight: 87px;
  --cardMaxHeight: 100px;

  // Editor
  --editorVerticalSpacing: 20px;

  // Material
  --material-input-padding-bottom: 1.34375em;
  --material-input-margin-bottom: 0.25em;

  --secondaryNavigationWidth: 200px;
}
