/* Text */
sup,
sub {
  vertical-align: baseline;
  position: relative;
}

sup {
  top: -0.4em;
}

.nav-link-icon {
  overflow: hidden;
}

.mat-panel-title-flex {
  align-items: center;
}

.mat-panel-title-flex > .mat-headline-6 {
  margin-bottom: 0px;
}

.mat-mdc-cell {
  color: var(--black);
  background: initial;
  padding: 0px 5px;
}

.mat-mdc-header-cell {
  padding: 0px 5px;
}

/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
.mat-option-text {
  line-height: var(--line-height-48px);
}

a.text-link {
  color: var(--textColor);
}

a.text-link:hover {
  color: var(--textColorLight);
  cursor: pointer;
}

a.text-link.disabled {
  pointer-events: none;
  cursor: not-allowed;
  color: var(--disabledGrey);
}

.mat-mdc-card {
  color: var(--black);
}

.show-less,
.show-more,
.show-all {
  color: var(--primaryThemeColor);
  font-size: var(--font-size-12px);
  text-decoration: underline;
}

.show-less:hover,
.show-more:hover,
.show-all:hover {
  cursor: pointer;
}

hr {
  color: var(--borderColor);
  border-color: var(--borderColor);
  border-top: 0px;
}
