/* Breakpoints */
$mat-xs: 'screen and (max-width: 599px)';
$mat-sm: 'screen and (min-width: 600px) and (max-width: 959px)';
$mat-md: 'screen and (min-width: 960px) and (max-width: 1279px)';
$mat-lg: 'screen and (min-width: 1280px) and (max-width: 1919px)';
$mat-xl: 'screen and (min-width: 1920px) and (max-width: 5000px)';
$mat-lt-sm: 'screen and (max-width: 599px)';
$mat-lt-md: 'screen and (max-width: 959px)';
$mat-lt-lg: 'screen and (max-width: 1279px)';
$mat-lt-xl: 'screen and (max-width: 1919px)';
$mat-gt-xs: 'screen and (min-width: 600px)';
$mat-gt-sm: 'screen and (min-width: 960px)';
$mat-gt-md: 'screen and (min-width: 1280px)';
$mat-gt-xl: 'screen and (min-width: 1920px)';

/* Special Ionic breakpoints */
$special-ios: 'only screen and (min-width: 768px)';
$special-ios-height: 'only screen and (min-width: 768px) and (min-height: 600px)';
