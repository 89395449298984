@use 'theme/breakpoints' as breakpoints;

:root {
  --tableColumnSidePadding: 12px;
  --tableColumnPadding: 5px;
}

/* Not customizable overwrites*/
.mat-sort-header-container .mat-sort-header-content {
  text-align: left;
}

.mdc-data-table__cell,
.mdc-data-table__header-cell,
.mdc-data-table__cell,
.mdc-data-table__header-cell {
  padding: 0px var(--tableColumnPadding);
}

mat-cell:first-of-type,
mat-header-cell:first-of-type,
mat-footer-cell:first-of-type {
  padding-left: var(--tableColumnSidePadding);
}

mat-cell:last-of-type,
mat-header-cell:last-of-type,
mat-footer-cell:last-of-type {
  padding-right: var(--tableColumnSidePadding);
}

td.mat-mdc-cell:last-child {
  border-right: 1px solid var(--borderColor);
  border-top-right-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
}

td.mat-mdc-cell.left-border:first-child {
  border-left: 1px solid var(--borderColor);
  border-top-left-radius: var(--borderRadius);
  border-bottom-left-radius: var(--borderRadius);
}

td.mat-mdc-cell {
  border-top: 1px solid var(--borderColor);
  border-bottom: 1px solid var(--borderColor);
}

.mdc-data-table__row:last-child td.mat-mdc-cell {
  border-bottom: 1px solid var(--borderColor);
}

/* Empty List */
.empty-list {
  width: 100%;
  font-size: var(--font-size-14px);
  height: 100%;
  color: var(--grey600);
}

.simple-empty-list {
  color: var(--grey600);
}

/* Table Search Bar */
.mat-mdc-form-field.in-list-search-bar-wrapper {
  justify-content: center;
  align-items: center;
  height: 50px;
  margin-bottom: 20px;
}

// Paginator
.mat-mdc-paginator {
  background: none !important;
}

.table-column-text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.mat-mdc-header-cell,
  .mat-sort-header-content {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

// Remberg Ngrx Table
.remberg-ngrx-table {
  &.sticky-header thead {
    position: sticky;
    background-color: var(--backgroundColor);
    top: 0;
    z-index: 2;
  }

  table.mat-mdc-table {
    --mat-table-row-item-outline-width: 0px;
    width: 100%;
    table-layout: fixed;
    background: transparent;
  }

  .mdc-data-table__cell,
  .mdc-data-table__header-cell {
    padding: 0px var(--tableColumnPadding);
  }

  mat-cell:first-of-type,
  mat-header-cell:first-of-type,
  mat-footer-cell:first-of-type {
    padding-left: var(--tableColumnSidePadding);
  }

  mat-cell:last-of-type,
  mat-header-cell:last-of-type,
  mat-footer-cell:last-of-type {
    padding-right: var(--tableColumnSidePadding);
  }

  .mat-mdc-cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    > div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }

  .card {
    position: relative;
    margin: 0px 0px 10px;
    &:not(.no-padding) {
      padding: var(--tableColumnSidePadding);
    }
    border-radius: var(--borderRadius);
    background-color: var(--white);
    overflow: hidden;
  }

  .card.is-clickable:hover {
    cursor: pointer;
    background-color: var(--cardHoverColor);
  }

  .mat-mdc-cell {
    /* Our borders are set on the row for the card views*/
    border-bottom-color: transparent !important;
  }

  th,
  .mat-mdc-header-cell {
    border-bottom: 0px solid transparent;
  }

  tr.mat-mdc-header-row {
    position: sticky;
    height: initial;
  }

  .table-row-lt-sm {
    height: 70px;
  }

  .table-row {
    display: table-row;
  }

  .table-column-padding {
    padding-left: var(--tableColumnPadding);
    padding-right: var(--tableColumnPadding);
  }

  .paginator {
    display: inline-block;
  }

  .paginator-wrapper {
    text-align: right;
  }

  .settings-icon-wrapper-paginator {
    display: inline-block;
  }

  .settings-icon-wrapper-paginator > mat-icon {
    display: inline-block;
    vertical-align: middle;
    font-size: var(--largeIcon);
    height: var(--largeIcon);
    width: var(--largeIcon);
    color: var(--iconColor);
    min-width: var(--largeIcon);
  }

  .mat-headline-6 {
    margin: 0px;
    white-space: nowrap;
  }

  // ToDo: refactor this
  // https://remberg.atlassian.net/browse/DES-375?atlOrigin=eyJpIjoiOWI0MDI5MTQyNDQyNDg1Nzk0ZjEyZTNmZTYwZTEyZWQiLCJwIjoiaiJ9
  .smaller-icon {
    font-size: var(--largeIcon);
    height: var(--largeIcon);
    width: var(--largeIcon);
    min-width: var(--largeIcon);
    margin-right: 3px;
  }

  .align-header-center ::ng-deep .mat-sort-header-container {
    justify-content: center;
  }
}

.remberg-ngrx-card-table {
  table.mat-mdc-table {
    width: 100%;
    table-layout: fixed;
    border-spacing: 0 10px;
    background: transparent;
  }

  .paginator {
    display: inline-block;
  }

  .paginator-wrapper {
    text-align: right;
  }

  .settings-icon-wrapper-paginator {
    display: inline-block;
  }

  .settings-icon-wrapper-paginator > mat-icon {
    display: inline-block;
    vertical-align: middle;
    font-size: var(--font-size-20px);
    width: var(--font-size-20px);
    min-width: var(--font-size-20px);
    height: var(--font-size-20px);
    color: var(--iconColor);
  }

  .paginator-responsive ::ng-deep .mat-mdc-paginator-range-label {
    margin: 0 10px;
  }
}

.highlighted-row {
  background-color: var(--grey100) !important;
}

.borderless-header {
  border-style: none;
  min-height: 0px;
  padding-bottom: 10px;
}

// Hierarchy table
.hierarchy-table {
  .mat-mdc-row:last-child {
    border-bottom: solid 1px var(--borderColor);
  }
  .mat-mdc-cell {
    border-top: solid 1px var(--borderColor);
  }

  .mat-mdc-cell,
  .mat-mdc-header-cell,
  .mat-mdc-footer-cell {
    &:first-of-type {
      padding-left: 0;
    }
  }
  .mat-mdc-cell,
  .mat-mdc-header-cell,
  .mat-mdc-footer-cell {
    &:last-of-type {
      padding-right: 0;
    }
  }

  .child-row,
  .loader-row {
    .mat-column-expander {
      border-top: none;
    }
  }

  .mat-column-expander,
  .mat-column-options {
    flex: 0 0 auto;
    justify-content: center;
    width: 45px;
  }

  .child-row-icon {
    -ms-transform: rotate(90deg) scale(1, -1);
    -webkit-transform: rotate(90deg) scale(1, -1);
    transform: rotate(90deg) scale(1, -1);
  }

  .mat-column-empty,
  .mat-column-loader {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .load-more {
    display: block;
    text-decoration: underline;
    font-weight: var(--font-weight-bold);
  }
}
