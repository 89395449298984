@use 'theme/breakpoints' as breakpoints;

html,
body {
  margin: 0;
  height: 100%;
  background-color: var(--backgroundColor);
}

body {
  overscroll-behavior-y: contain;
}

[hidden] {
  display: none !important;
}

.padded {
  padding: var(--interfacePadding);
}

.detail-page {
  margin-left: var(--negativeInterfacePadding);
}

.with-icon {
  display: flex;
  align-items: center;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.left {
  margin-right: auto !important;
}

.right {
  margin-left: auto !important;
}

.bottom {
  margin-top: auto !important;
}

.form-container {
  display: flex;
  flex-direction: column;
}

.mat-mdc-card.map-card {
  padding: 0px;
}

// automatically merge sequence of dividers into a single divider
// usage:                 <mat-divider auto></mat-divider>
// define gap:            <mat-divider auto gap="10"></mat-divider>
// force always display:  <mat-divider auto fixed></mat-divider>
.mat-divider[auto] {
  margin: 30px 0;

  @each $gap in 0, 10, 15, 20, 30 {
    &[gap='#{$gap}'] {
      margin: #{$gap}px 0;
    }
  }

  &:has(+ .mat-divider[auto]),
  &:first-child,
  &:last-child {
    &:not([fixed]) {
      display: none;
    }
  }
}

.table-subtext {
  color: var(--iconColor);
  font-size: var(--font-size-14px);
}

.table-unassigned {
  color: var(--iconColor);
}

.mat-horizontal-stepper-header-container {
  max-width: 750px;
}

.reset-button {
  background-color: var(--grey400);
  color: var(--white);
}

@media #{breakpoints.$mat-lt-sm} {
  .mat-stepper-horizontal ::ng-deep .mat-horizontal-content-container {
    padding: 0;
  }
}

.form-button-group {
  margin-top: 10px;
}

:root {
  --height: 100vh;
}

ion-modal::part(content) {
  --height: 100vh;
  --height: calc(var(--vh, 1vh) * 100);
}

// special breakpoints for ios - refactor with care
@media #{breakpoints.$special-ios-height} {
  ion-modal::part(content) {
    --height: 500px;
    --width: 500px !important;
  }

  .plt-ios ion-modal::part(content),
  .plt-android ion-modal::part(content) {
    --height: 600px !important;
    --width: 600px !important;
  }
}

// special breakpoints for ios - refactor with care
@media #{breakpoints.$special-ios} {
  ion-modal::part(content) {
    --height: 500px !important;
    --width: 500px !important;
    --max-height: 100vh !important;
  }

  .plt-ios ion-modal::part(content),
  .plt-android ion-modal::part(content) {
    --height: 600px !important;
    --width: 600px !important;
    --max-height: 100vh !important;
  }
}
