/* File List Item in Mat List */
.file-list-download-icon {
  width: 50px;
}

#file-upload-progress {
  border: 1px solid var(--borderColor);
  padding: 0;
  border-radius: var(--borderRadius);
  height: initial;
  overflow: hidden !important;
}

.mat-mdc-list-base .mat-mdc-list-item.file {
  height: initial !important;
  margin: 10px 0px !important;
  max-width: 350px;
}

.mat-mdc-list-base .mat-mdc-list-item.file .download-action-icon {
  margin-left: 10px;
}

.mat-mdc-list-base .mat-mdc-list-item.file .show-on-hover {
  display: none;
}

.mat-mdc-list-base .mat-mdc-list-item.file:hover .show-on-hover {
  display: inline-block;
}

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
.mat-mdc-list-base .mat-mdc-list-item.file .mdc-list-item__content > a {
  color: var(--black);
}

/* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
.mat-mdc-list-base .mat-mdc-list-item.file .mdc-list-item__content {
  min-height: 40px;
  background: var(--chatBackgroundColor);
  padding: 0px;
  box-shadow: none !important;
  border: 1px solid var(--borderColor) !important;
  border-radius: var(--borderRadius) !important;
}
