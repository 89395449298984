/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.cdk-overlay-pane .tree-node-group .mat-mdc-icon-button:hover .mat-button-focus-overlay {
  opacity: 0.1 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.cdk-overlay-pane .tree-node-group .mat-checkbox-disabled .mat-checkbox-label {
  color: var(--disabledText);
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.cdk-overlay-pane
  .tree-node-group
  .mat-checkbox-disabled:not(.mat-checkbox-checked)
  .mat-checkbox-frame {
  color: var(--disabledInputIconColor);
}

.cdk-overlay-pane .user-multiselect-panel {
  max-height: calc(100vh - 220px);
  position: relative;
  top: 15px;
}
