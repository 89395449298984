/* Labels & Status Indicators*/
.NONE:after {
  color: var(--statusGrey);
}

.LOW:after {
  color: var(--statusYellow);
}

.MEDIUM:after {
  color: var(--statusOrange);
}

.HIGH:after {
  color: var(--statusRed);
}

.status-icon.green {
  color: var(--statusGreen);
}

.status-icon.yellow {
  color: var(--statusOrange);
}

.status-icon.red {
  color: var(--statusRed);
}

.status-icon.white {
  color: var(--white);
}

.Running:before {
  color: var(--statusGreen);
}

.Warning:before {
  color: var(--statusOrange);
}

.Stopped:before {
  color: var(--statusRed);
}

.open-status-indicator,
.pending-status-indicator,
.solved-status-indicator,
.closed-status-indicator,
.new-status-indicator {
  width: 12px;
  height: 12px;
  margin-right: 15px;
  align-self: center;
}

.new-status-indicator {
  background-color: var(--statusBlue);
}

.open-status-indicator {
  background-color: var(--statusRed);
}

.pending-status-indicator {
  background-color: var(--statusOrange);
}

.solved-status-indicator {
  background-color: var(--statusGreen);
}

.closed-status-indicator {
  background-color: var(--statusGrey);
}

.left-border-priority-030_critical {
  border-left: 3px solid var(--statusRed) !important;
}

.left-border-priority-020_high {
  border-left: 3px solid var(--statusRed) !important;
}

.left-border-priority-010_normal {
  border-left: 3px solid var(--statusOrange) !important;
}

.left-border-priority-000_low {
  border-left: 3px solid var(--statusGreen) !important;
}

.left-border-priority-none {
  border-left: 3px solid var(--statusNone) !important;
}

td.left-border.priority-scheduling-030_critical:first-child {
  border-left: 3px solid var(--statusRed) !important;
}

td.left-border.priority-scheduling-020_high:first-child {
  border-left: 3px solid var(--statusRed) !important;
}

td.left-border.priority-scheduling-010_normal:first-child {
  border-left: 3px solid var(--statusOrange) !important;
}

td.left-border.priority-scheduling-000_low:first-child {
  border-left: 3px solid var(--statusGreen) !important;
}

td.left-border.priority-scheduling-none:first-child {
  border-left: 3px solid var(--black) !important;
}
