.cio-pl {
  width: 20px !important;
  height: 20px !important;
  margin-bottom: 0px auto !important;
}

.cio-lm {
  margin-top: 20px !important;
  font-family: var(--roboto) !important;
  margin-bottom: 0px !important;
  display: none !important;
}

.cio-container-loader {
  height: 100% !important;
  font-size: var(--font-size-14px) !important;
}
