$translation-y-axis: -60px;

//custom class to properly place the dropdown menu for multiselect (assets,users,organizations etz.)
.mdc-select-base-multiselect {
  transform: translate(0px, #{$translation-y-axis}) !important;
}

.mdc-select-right-aligned-panel {
  // panel will adjust width according to the content within 350-450px range
  min-width: 350px !important;
  width: unset !important;
  max-width: 450px !important;
  margin-left: auto; // align panel to the right
  transform: translate(0px, #{$translation-y-axis}) !important;
}

.mat-mdc-select-arrow {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='rgba(0, 0, 0, 0.54)' width='24px' height='24px' focusable='false' aria-hidden='true' class='ng-tns-c3393473648-8'%3E%3Cpath d='M7 10l5 5 5-5z' class='ng-tns-c3393473648-8'%3E%3C/path%3E%3C/svg%3E%0A");
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) {
  font-weight: var(--font-weight-bold);
}

ngx-mat-select-search .mat-select-search-inner {
  appearance: none;
  font: inherit;
  line-height: inherit;
  background: var(--white);
  border: none;
  outline: none;
  margin: 0;
  width: 100%;
  max-width: 100%;
  vertical-align: bottom;
  text-align: inherit;
  box-sizing: content-box;
  padding-top: 6px;
}

/* Positions the clear button insides the search dropdown */
ngx-mat-select-search .mat-select-search-inner .mat-select-search-clear {
  top: 5px;
  right: 0px;
}

ngx-mat-select-search .mat-select-search-no-entries-found {
  padding: 14px 0px !important;
  margin-top: 8px;
  opacity: 0.38;
}

/* handle dedicated class for back arrow in input */
ngx-mat-select-search {
  &.back-arrow-container {
    .mat-select-search-inner-row {
      display: flex;
      flex-direction: row-reverse;
      .mat-select-search-input {
        padding-left: 10px;
      }
    }
  }
}

div.mat-mdc-select-panel.contact-multiselect-panel {
  overflow-x: hidden;
}
