.mat-mdc-list-base {
  --mdc-list-list-item-label-text-size: var(--font-size-16px);
  --mdc-list-list-item-leading-icon-color: var(--iconColor);
  --mdc-list-list-item-hover-leading-icon-color: var(--iconColor);
}

.mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: var(--mdc-list-list-item-one-line-container-height);
}

.mdc-list-item__content .mat-mdc-list-item-unscoped-content {
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.mat-mdc-list-item.mdc-list-item,
.mat-mdc-list-item.mdc-list-item .mat-mdc-list-item__content .mdc-list-item__primary-text {
  padding: 0;
}
