@use 'theme/breakpoints' as breakpoints;

/* Variables */
:root,
.plt-ios ion-modal::part(content) {
  --toolbarHeight: 64px;
  --fullScreenDialogHeaderHeight: 110px;
}

.ios {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .mat-mdc-paginator-page-size {
    display: none;
  }
}

.ios:not(.plt-mobileweb) mat-toolbar {
  padding-top: env(safe-area-inset-top);
  padding-right: env(safe-area-inset-right);
  padding-left: env(safe-area-inset-left);
  height: calc(var(--toolbarHeight) + env(safe-area-inset-top));

  .header-toolbar {
    padding-left: 20px;
    padding-right: 10px;
  }

  .menu-replace-toolbar {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.ios:not(.plt-mobileweb) mat-sidenav-container {
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);

  .mat-drawer-content.interface-sidenav-content {
    margin-top: var(--toolbarHeight);
    height: calc(100% - var(--toolbarHeight));

    .interface-padded {
      height: calc(100% - 40px);

      app-forms {
        height: 100%;
      }

      &.padded {
        padding-right: max(var(--dialogTopPadding), env(safe-area-inset-right));
        padding-left: max(var(--dialogTopPadding), env(safe-area-inset-left));
      }
    }
  }
}

.ios:not(.plt-mobileweb) mat-sidenav {
  top: calc(env(safe-area-inset-top) + var(--toolbarHeight)) !important;
  height: calc(100% - var(--toolbarHeight));
}

/* Dialog implementation */
.ios:not(.plt-mobileweb) .notification-popup-menu-modal {
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
}
.ios.plt-mobileweb .notification-popup-menu-modal {
  height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
}

html.ios:not(.plt-mobileweb) ion-modal #rembergModalWrapper {
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
}

/* New Dialog implementation */
// This is the panel class used by material angular based dialogs
// a change here will affect all these dialogs
.mat-mdc-dialog-container .mdc-dialog__surface {
  // this must be set to 0 to avoid seeing content behind the dialog header on scrolling
  padding-top: 0 !important;
  padding-right: max(var(--dialogTopPadding), env(safe-area-inset-right));
  padding-bottom: max(var(--dialogTopPadding), env(safe-area-inset-bottom));
  padding-left: max(var(--dialogTopPadding), env(safe-area-inset-left));
}
