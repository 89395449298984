@use 'theme/breakpoints' as breakpoints;

// Scrollbar position
@mixin scrollbar-position {
  margin-right: -15px;
  padding-right: 15px;
}

// Default Calculations
@mixin default-overview-page {
  height: 100vh;
  max-height: calc(
    100vh -
      (var(--mainNavigationToolbarHeight) + var(--interfacePadding) + var(--toolbarHeaderHeight))
  );
  overflow-y: auto;
  @include scrollbar-position;
}

@mixin default-overview-page-split-map-mobile {
  height: 100vh;
  max-height: calc(
    100vh -
      (
        var(--mainNavigationToolbarHeight) + var(--interfacePadding) + var(--toolbarHeaderHeight) +
          var(--interfacePadding) + 80px
      )
  );
  overflow: initial;
  @include scrollbar-position;
}

@mixin default-detail-page-height {
  height: 100vh;
  max-height: calc(
    100vh -
      (var(--mainNavigationToolbarHeight) + var(--interfacePadding) + var(--simpleHeaderHeight))
  );
}

@mixin default-detail-page {
  @include default-detail-page-height;
  overflow-y: auto;
  @include scrollbar-position;
}

@mixin default-detail-page-scheduling-board {
  min-height: calc(
    100vh -
      (var(--mainNavigationToolbarHeight) + var(--interfacePadding) + var(--simpleHeaderHeight))
  );
  height: calc(
    100vh -
      (var(--mainNavigationToolbarHeight) + var(--interfacePadding) + var(--simpleHeaderHeight))
  );
  max-height: calc(
    100vh -
      (var(--mainNavigationToolbarHeight) + var(--interfacePadding) + var(--simpleHeaderHeight))
  );
  @include scrollbar-position;
}

@mixin default-page-with-footer-padding {
  height: 100vh;
  max-height: calc(100vh - (var(--mainNavigationToolbarHeight) + 2 * var(--interfacePadding)));
  overflow-y: auto;
  @include scrollbar-position;
}

@mixin default-settings-page-with-simple-header-and-tabs-and-searchbar {
  height: 100vh;
  // 68px for mat-tab-group + margin + searchbar + margins
  max-height: calc(
    100vh -
      (
        var(--mainNavigationToolbarHeight) + var(--interfacePadding) + var(--simpleHeaderHeight) +
          68px + 68px + 20px
      )
  );
  overflow-y: auto;
  @include scrollbar-position;
}

@mixin default-settings-page-with-simple-header-and-tabs {
  height: 100vh;
  max-height: calc(
    100vh -
      (
        var(--mainNavigationToolbarHeight) + var(--interfacePadding) + var(--simpleHeaderHeight) +
          58px + 24px
      )
  );
  overflow-y: auto;
  @include scrollbar-position;
}

.page-not-found-wrapper {
  height: 100vh !important;
  @include default-page-with-footer-padding;
}

// Asset Overview
.asset-type-data-wrapper {
  @media #{breakpoints.$mat-gt-xs} {
    @include default-overview-page;

    .remberg-ngrx-table thead {
      position: sticky;
      background-color: var(--backgroundColor);
      top: 0px;
      z-index: 2;
      th {
        padding-bottom: 10px;
      }
    }
  }
}

.asset-data-wrapper {
  // Asset Map Overview
  .asset-map {
    @media #{breakpoints.$mat-gt-xs} {
      .assets-map {
        @include default-overview-page;
        overflow-y: initial;
      }
    }
    @media #{breakpoints.$mat-lt-sm} {
      .assets-map {
        @include default-overview-page-split-map-mobile;
      }
    }
  }

  // Asset Table Overview
  .asset-table {
    @media #{breakpoints.$mat-gt-xs} {
      @include default-overview-page;

      // TODO: clashes with table border spacing
      &:not(.asset-table-split-view) {
        .remberg-ngrx-table thead {
          position: sticky;
          background-color: var(--backgroundColor);
          top: 0px;
          z-index: 2;

          th {
            padding-bottom: 10px;
          }
        }
      }
    }
  }

  // Asset Split Overview
  .asset-table-split-view {
    @media #{breakpoints.$mat-gt-xs} {
      @include default-overview-page;

      .remberg-ngrx-table thead {
        position: sticky;
        background-color: var(--backgroundColor);
        top: 0px;
        z-index: 2;

        th {
          padding-bottom: 10px;
        }
      }
    }
    @media #{breakpoints.$mat-lt-sm} {
      height: initial;
    }
  }
  .asset-map-split-view {
    .assets-map {
      @media #{breakpoints.$mat-gt-xs} {
        @include default-overview-page;
      }
      @media #{breakpoints.$mat-lt-sm} {
        @include default-overview-page-split-map-mobile;
      }
    }
  }
}

// File Drive Overview
.directory-drop-wrapper {
  @include default-overview-page;

  app-file-drive-table-header {
    position: sticky;
    background-color: var(--backgroundColor);
    top: 0px;
    z-index: 2;
    display: block;
  }
}

// Files Asset Detail Section
.asset-detail-directory-drop-wrapper {
  @include default-overview-page;

  app-file-drive-table-header {
    position: sticky;
    background-color: var(--white);
    top: 0px;
    z-index: 2;
    display: block;
  }
}

// Remberg Default Overview
.rb-overview-page,
.work-order-table-split-view,
.maintenance-plan-data-wrapper,
.remberg-users-overview-page-settings {
  @media #{breakpoints.$mat-gt-xs} {
    @include default-overview-page;

    .remberg-ngrx-table thead {
      position: sticky;
      background-color: var(--backgroundColor);
      top: 0px;
      z-index: 2;

      th {
        padding-bottom: 10px;
      }
    }
  }
  &.work-order-table-split-view {
    @media #{breakpoints.$mat-lt-sm} {
      height: initial;
    }
  }
}

// Work Order Overview
.work-order-data-wrapper {
  @media #{breakpoints.$mat-gt-xs} {
    .work-order-table {
      @include default-overview-page;

      &:not(.work-order-table-split-view) {
        .remberg-ngrx-table thead {
          position: sticky;
          background-color: var(--backgroundColor);
          top: 0px;
          z-index: 2;

          th {
            padding: 10px 0;
          }
        }
      }
    }
  }

  .work-order-map-split-view {
    .work-order-map {
      @media #{breakpoints.$mat-gt-xs} {
        @include default-overview-page;
      }
      @media #{breakpoints.$mat-lt-sm} {
        @include default-overview-page-split-map-mobile;
      }
    }
  }

  .work-order-map {
    @media #{breakpoints.$mat-gt-xs} {
      @include default-overview-page;
    }
    @media #{breakpoints.$mat-lt-sm} {
      @include default-overview-page-split-map-mobile;
    }
  }
}

// Map v2
.map-v2-wrapper {
  @media #{breakpoints.$mat-gt-xs} {
    @include default-overview-page;
  }
  @media #{breakpoints.$mat-lt-sm} {
    @include default-overview-page-split-map-mobile;
  }
}

// Scheduling Board
.board-wrapper {
  @include default-detail-page-scheduling-board;
}

// Task Overview
.task-overview-data {
  @media #{breakpoints.$mat-gt-xs} {
    @include default-detail-page;
  }
}

// Form Template Overview
.form-template-data {
  @media #{breakpoints.$mat-gt-xs} {
    @include default-detail-page;
    display: block;
  }
}

.form-instance-data {
  @media #{breakpoints.$mat-gt-xs} {
    @include default-overview-page;
    display: block;

    // TODO: clashes with table border spacing
    .remberg-ngrx-table thead {
      position: sticky;
      background-color: var(--backgroundColor);
      top: 0px;
      z-index: 2;

      th {
        padding-bottom: 10px;
      }
    }
  }
}

// Profile Settings
.profile-settings-scrolling {
  @media #{breakpoints.$mat-gt-xs} {
    @include default-detail-page;
  }
}

// Case Detail
.case-detail-sidenav-content {
  .case-detail-tab-group-wrapper {
    @media #{breakpoints.$mat-gt-xs} {
      @include default-detail-page-height;
    }
  }
}
.case-detail-associations-scrolling {
  @media #{breakpoints.$mat-gt-xs} {
    @include default-detail-page;
    overflow-x: hidden;
    width: 100%;
    margin-left: -20px;
    margin-right: -20px;
    padding: 0px 20px;
  }
}

// Work Order Detail
.work-order-detail-sidenav-content {
  .work-order-detail-main-scrolling {
    @media #{breakpoints.$mat-gt-xs} {
      @include default-detail-page;
      margin-right: initial;
      padding-right: 20px;
    }
  }
}
.work-order-detail-associations-scrolling {
  @media #{breakpoints.$mat-gt-xs} {
    @include default-detail-page;
    width: 100%;
    margin-left: -20px;
    margin-right: -20px;
    padding: 0px 20px;
    overflow-x: hidden;
  }
}

// Maintenance Plan Detail
.maintenance-plan-detail-sidenav-content {
  @media #{breakpoints.$mat-gt-xs} {
    @include default-detail-page;
    margin-right: 0px;
    padding-right: 0px;
    overflow-x: hidden;
  }
}
.maintenance-plane-detail-associations-scrolling {
  @media #{breakpoints.$mat-gt-xs} {
    @include default-detail-page;
    width: 100%;
    margin-left: -20px;
    margin-right: -20px;
    padding: 0px 20px;
    overflow-x: hidden;
  }
}

// Task Detail
.task-detail-data {
  @media #{breakpoints.$mat-gt-xs} {
    @include default-detail-page;
  }
}

// Scrolling with Tab
.form-instance-detail-page-scrolling {
  @media #{breakpoints.$mat-gt-xs} {
    @include default-detail-page;

    .mat-mdc-tab-group.form-instance-detail-tab-group {
      position: sticky;
      top: 0px;
      z-index: 2;
      background: var(--backgroundColor);
    }
  }
}

// Settings
.appearance-settings-content,
.configuration-settings-content,
.case-settings-content,
.account-settings-section,
.security-settings-content,
.localization-settings-scrolling,
.organization-settings-content,
.general-information-settings-content,
.user-access-request-content,
.work-order-settings-content,
.appointment-settings-content,
.assets-settings-content,
.cases-settings-content {
  @media #{breakpoints.$mat-gt-xs} {
    @include default-detail-page;
  }
}

.remberg-users-user-roles-settings,
.remberg-users-user-groups-settings,
.remberg-users-groups-asset-settings {
  @media #{breakpoints.$mat-gt-xs} {
    @include default-settings-page-with-simple-header-and-tabs-and-searchbar;
  }
}

.remberg-users-user-roles-permission-settings {
  @media #{breakpoints.$mat-gt-xs} {
    @include default-settings-page-with-simple-header-and-tabs;
  }
}

// Special Pages
.imports-content,
.exports-content,
.security-api-content {
  @media #{breakpoints.$mat-gt-xs} {
    @include default-detail-page;

    table.mat-mdc-table.remberg-table thead,
    table.mat-mdc-table thead {
      position: sticky;
      background-color: var(--backgroundColor);
      top: 0px;
      z-index: 2;
    }
  }
}
