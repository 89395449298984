.date-time-footer-wrapper {
  position: absolute;
  bottom: 20px;
  width: 100%;
}

.clear-button {
  position: absolute;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 4px;
  background: transparent;
  border: none;
  border-radius: var(--borderRadius);
  padding: 4px 8px;
  color: var(--grey600);
  font-size: var(--font-size-12px);
  font-weight: var(--font-weight-regular);
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: var(--grey15);
  }

  > .icon {
    font-size: var(--xSmallIcon) !important;
    height: var(--xSmallIcon) !important;
    width: var(--xSmallIcon) !important;
    min-width: var(--xSmallIcon) !important;
  }
}
