/* Stepper */
.mat-step-icon .mat-icon {
  min-width: 16px;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  color: var(--textColorOnPrimaryBackground);
}

.cdk-drag-preview {
  z-index: 30100 !important;
}

.mat-horizontal-stepper-content,
.mat-vertical-stepper-content {
  padding-top: 5px;
}
