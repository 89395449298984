/* Badge */
.mat-badge-content {
  --mat-badge-background-color: var(--statusBlue);
  --mat-badge-text-color: var(--white);
}

.mat-badge-accent .mat-badge-content {
  --mat-badge-background-color: var(--black);
  --mat-badge-text-color: var(--white);
}

.mat-badge-primary .mat-badge-content {
  --mat-badge-background-color: var(--primaryThemeColor);
  --mat-badge-text-color: var(--textColorOnPrimaryBackground);
}

.mat-badge-warn .mat-badge-content {
  --mat-badge-background-color: var(--statusWarn);
  --mat-badge-text-color: var(--white);
}
